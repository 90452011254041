import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tm-event-type-icon',
  templateUrl: 'tm-event-type-icon.component.html',
  styleUrls: ['tm-event-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmEventsTypeIconComponent {
  @Input() name: string;
  @Input() protocol: string;
  @Input() mnemo: string;
  @Input() serviceCode: string;
}
