import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TmAsyncValidatorsService {
  private _sessionValidationCache: any = {};

  constructor(private _http: HttpClient, private cd: ChangeDetectorRef) {}

  /**
   * Get validator to check control on server(GPG-1677).
   * control.parent.getRawValue() is sent on server
   * @param dataTransformFn if server needs transformed data
   */
  public getAsyncObjectValidation(
    // eslint-disable-next-line
    resourceName:
      | 'visibilityArea'
      | 'user'
      | 'role'
      | 'tag'
      | 'ldapStatus'
      | 'systemList'
      | 'systemListItem'
      | 'category'
      | 'term'
      | 'perimetersGroup'
      | 'textObject'
      | 'EtForm'
      | 'EtStamp', // расширить по необходимости
    id$: Observable<any>,
    controlsToCheck: string[] = [],
    dataTransformFn = (data: any) => data
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (control.pristine) {
        return of(null);
      }
      let currentValue: any = {};

      if (control.parent) {
        // get control name
        const parentControls = control.parent.controls;
        const controlNameToGetErrors = Object.keys(parentControls).find(
          // @ts-ignore
          (controlName) => parentControls[controlName] === control
        )!;

        controlsToCheck = controlsToCheck.filter((name) => name !== controlNameToGetErrors);
        controlsToCheck.unshift(controlNameToGetErrors);
      }
      let fullValue = control instanceof FormGroup ? control.getRawValue() : control.parent?.getRawValue();
      controlsToCheck.forEach((contr) => {
        currentValue[contr] = fullValue[contr];
      });

      return timer(500).pipe(
        switchMap(() => id$),
        switchMap((id) => this._getRequestObj(resourceName, controlsToCheck, currentValue, dataTransformFn, id)),
        catchError((response: HttpErrorResponse) => of(response.error)),
        tap((data) => this._cacheValidationResponse(resourceName, controlsToCheck, currentValue, data)),
        map((data: any) => {
          if (!data.meta) {
            return null;
          }
          if (control instanceof FormGroup) {
            return controlsToCheck.reduce((acc, current) => {
              const errors = data.meta[current] || data.meta[current.toUpperCase()];
              if (errors) {
                acc[current] = errors;
              }
              return acc;
            }, {} as any);
          }
          return data.meta[controlsToCheck[0]] || data.meta[controlsToCheck[0].toUpperCase()];
        }),
        tap(() => this.cd.markForCheck()),
        take(1)
      );
    };
  }

  /**
   * Server validation of object
   * @param model data
   * @param resourceName name of resource, for example: visibilityArea, user
   * @param objId id of validated object
   */
  private _sendRequestToCheckModel(model: any, resourceName: string, objId: string | null): Observable<any> {
    if (!objId) {
      return this._http.post(`/api/${resourceName}/validate`, model);
    } else {
      return this._http.put(`/api/${resourceName}/${objId}/validate`, model);
    }
  }

  /**
   * get value from cache
   */
  private _tryToGetCachedValue(resourceName: string, controlName: string[], value: any): Observable<any> | null {
    if (!value) {
      return null;
    }
    const cachedResponse = this._sessionValidationCache[
      `${resourceName}.${controlName.toString()}.${JSON.stringify(value)}`
    ];
    return cachedResponse ? of(cachedResponse) : null;
  }

  /**
   * cache validation response
   */
  private _cacheValidationResponse(
    resourceName: string,
    controlNames: string[],
    value: any,
    responseFromServer: any
  ): void {
    if (value) {
      this._sessionValidationCache[
        `${resourceName}.${controlNames.toString()}.${JSON.stringify(value)}`
      ] = responseFromServer;
    }
  }

  /**
   * Get request or cached value
   */
  private _getRequestObj(
    resourceName: string,
    controlNames: string[],
    value: any,
    dataTransformFn: (data: any) => any,
    id: string
  ): Observable<any> {
    return (
      this._tryToGetCachedValue(resourceName, controlNames, value) ||
      this._sendRequestToCheckModel(dataTransformFn(value), resourceName, id)
    );
  }
}
