import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, timer, iif, defer } from 'rxjs';
import { map, retryWhen, shareReplay, switchMap, switchMapTo, tap } from 'rxjs/operators';

const DEFAULT_RETRY_LIMIT = 10;
const DEFAULT_RETRY_TIMEOUT = 1000;

type HttpOptions = Parameters<HttpClient['get']>[1];

/**
 * Stateful класс для редко запрашиваемых сущностей.
 * TODO: Generic Deserialized type is not always suitable. Think of making better interface
 */
@Injectable()
export abstract class TmStatefulService<Deserialized> {
  public abstract src: string;

  protected data$ = new BehaviorSubject<Deserialized | null | undefined>(null);

  public sharedData = defer(() =>
    this.data$.pipe(switchMap((data) => iif(() => !!data, this.data$, this.get().pipe(switchMapTo(this.data$)))))
  ).pipe(shareReplay(1)) as Observable<Deserialized>;

  constructor(protected http: HttpClient) {}

  /**
   * Refresh stored data once
   */
  public refresh(): Observable<Deserialized> {
    return this.get();
  }

  public get(options?: HttpOptions): Observable<Deserialized> {
    return this.http.get(this.src.toString(), options).pipe(
      map((response) => this.deserialize(response)),
      tap((response) => this.saveLatestData(response))
    );
  }

  public getWithRetries(
    retryDelayMs = DEFAULT_RETRY_TIMEOUT,
    retryLimit = DEFAULT_RETRY_LIMIT
  ): Observable<Deserialized> {
    return this.get().pipe(
      retryWhen((errors$) => {
        return errors$.pipe(
          switchMap((err) => {
            return retryDelayMs > -1 && --retryLimit > 0 ? timer(retryDelayMs) : throwError(err);
          })
        );
      })
    );
  }

  /**
   * Fix data from backend for frontend if needed
   */
  protected deserialize(response: unknown): Deserialized {
    return response as Deserialized;
  }

  /**
   * Save data for sharing via sharedData
   */
  protected saveLatestData(response: Deserialized | null | undefined): void {
    this.data$.next(response);
  }
}
