import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookwormEventItem } from './bookworm-events.model';

@Injectable({
  providedIn: 'root',
})
export class TmBookwormEventsService extends TmStatefulService<TmApi.GetResponse<BookwormEventItem[]>> {
  public src = '/api/bookworm/events';

  public getIdByMnemo(mnemo: string) {
    return this.sharedData.pipe(map((response) => response.data.find((item) => item.mnemo === mnemo)?.event_id));
  }
  public getNameById(key: string): Observable<string> {
    return this.sharedData.pipe(map((response) => response.data.find((item) => item.event_id === key)?.name || key));
  }

  public getNameByMnemo(mnemo: string): Observable<string> {
    return this.sharedData.pipe(map((response) => response.data.find((item) => item.mnemo === mnemo)?.name || mnemo));
  }
}
