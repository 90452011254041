import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogRef } from '@platform/shared';
import { BehaviorSubject } from 'rxjs';
import { ButtonType } from '@platform/shared/lib/components/iw-button/iw-button.component';

type buttonsOptions = { text: string; click: () => void; color: ButtonType; disabled?: boolean; popoverText?: string };

@Component({
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent<T = unknown> {
  private _defaultButtons: buttonsOptions[] = [
    {
      click: () => {
        this._dialog.close(undefined);
      },
      color: 'success',
      text: this._t.instant('@tm-shared.modals.yes'),
    },
    {
      click: () => {
        this._dialog.dismiss(undefined);
      },
      text: this._t.instant('@tm-shared.modals.no'),
      color: '',
    },
  ];
  /**
   * Title
   */
  @Input() public title = '';

  /**
   * Question
   */
  @Input() public text = '';

  @Input() public buttons: buttonsOptions[] = this.getDefaultButtons();

  public showSpinner = new BehaviorSubject<boolean>(false);

  constructor(protected _t: TranslateService, private _dialog: ModalDialogRef<T>) {}

  public getDefaultButtons(): buttonsOptions[] {
    return [...this._defaultButtons];
  }

  public getPopoverText(button: buttonsOptions): string {
    return button.popoverText || '';
  }

  public getDisabledStatus(button: buttonsOptions): boolean {
    return button.disabled || false;
  }

  public setSpinnerVisibility(showSpinner: boolean) {
    this.showSpinner.next(showSpinner);
  }
}
