<iw-tree
  style="height: 300px;"
  [data]="startItems | async"
  [options]="{getChildren, indeterminate: false}"
  [selected]="checked"
  (selectedChange)="onTreeCheckedChange($event)"
  [(expanded)]="expanded"
>
</iw-tree>

<div>
  <hr />
  <h3>{{ '@tm-shared.search-select.searchTitles.deletedGroup' | translate }}</h3>
  <tm-grid [height]="100" [service]="searchService" [columnDefs]="columnDefs" [tableConfig]="config"></tm-grid>
</div>
