<div *ngIf="showOnPage" class="configPanel">
  <div class="configState">
    <tm-spinner class="widget-spinner" *ngIf="inProgress"></tm-spinner>
    <span>{{ applyingConfigurationStatusText }}</span>
    <span *ngIf="processing && timer" class="configTimer"
      ><tm-icon svgIcon="clock" class="clockIcon"></tm-icon>{{ timer }}</span
    >
    <span>{{ localConfigurationStatusText }}</span>
    <span *ngIf="showUsername" class="configUsername" [iwPopover]="usernameTpl">{{ username }}. </span>
    <span>{{ training }}</span>

    <button
      iw-button
      *ngIf="showButtons"
      [disableClickEventOnly]="disableApply"
      [iwPopover]="disableApplyReason | translate"
      type="button"
      class="widget-button"
      (click)="openConfigModal('apply', processing)"
    >
      {{ 'config.widget.local.apply' | translate }}
    </button>
    <button
      iw-button
      *ngIf="showButtons && !hideSaveButton"
      type="button"
      class="widget-button"
      (click)="openConfigModal('save', processing)"
    >
      {{ 'config.widget.local.save' | translate }}
    </button>
    <button
      iw-button
      *ngIf="showRevertButton"
      [disableClickEventOnly]="disableRevert"
      [iwPopover]="disableRevertReason | translate"
      type="button"
      class="widget-button"
      (click)="openConfigModal('revert', processing)"
    >
      {{ 'config.widget.local.revert' | translate }}
    </button>
    <span *ngIf="showLastEdited">{{ lastEdited }}</span>
    <span *ngIf="localConfig && !processing"
      >{{ 'config.widget.title.version' | translate: { version: localConfig.config.CHANGE_ID } }}
    </span>
    <tm-icon svgIcon="warning" class="configWarning" *ngIf="showWarning" (click)="openWarningModal()"></tm-icon>
  </div>
</div>

<ng-template #usernameTpl>
  <div class="configFullUsername">
    {{ username }}
  </div>
</ng-template>
