import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class TmFormErrorsService {
  public setErrors(data: any, form: FormGroup) {
    if (data && data.meta) {
      Object.keys(data.meta).forEach((controlName) => {
        if (form.get(controlName) || form.get(controlName.toLowerCase())) {
          (form.get(controlName) || form.get(controlName.toLowerCase()))!.setErrors(data.meta[controlName]);
        }
      });
    }
  }
}
