import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TmDownloaderService {
  constructor(private http: HttpClient) {}

  public download(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  public downloadFileFromUrl(path: string) {
    return this.http.get(path, { responseType: 'blob', observe: 'response' }).pipe(
      tap((response) => {
        const header = response.headers.get('Content-Disposition');
        if (!header) {
          return;
        }
        const startIndex = header.indexOf('filename=') + 10;
        const endIndex = header.length - 1;
        this.download(response.body as Blob, header.substring(startIndex, endIndex));
      })
    );
  }
}
