import { NgModule } from '@angular/core';
import { TmIconComponent } from './tm-icon.component';
import { IwIconModule } from '@platform/shared';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TmIconComponent],
  imports: [IwIconModule, CommonModule],
  exports: [TmIconComponent],
})
export class TmIconModule {}
