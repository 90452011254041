import { IconMnemoMap } from './tm-event-icon.model';
import { IconName } from '../../typings/generated/icons';
import { firstValueFrom, map } from 'rxjs';
import { TmBookwormServicesService } from '../bookworm';
import { Injectable } from '@angular/core';

@Injectable()
export class TmEventIconService {
  constructor(private _bookwormService: TmBookwormServicesService) {}

  public getEventIconName(name: string): IconName | undefined {
    return IconMnemoMap.get(name);
  }

  public async getFallbackImageUrl(event: ErrorEvent, id: string | undefined) {
    const mnemo = await firstValueFrom(this.getServiceMnemo(id));
    if (event.target && mnemo) {
      (<HTMLImageElement>event.target).src = `/img/modules/events/eventType/eventType__${mnemo}.svg`;
    }
  }

  public getIconUrl(mnemo: string | undefined) {
    return `/img/icons/${mnemo}.png`;
  }

  public getServiceMnemo(serviceId: string | undefined) {
    return this._bookwormService.sharedData.pipe(
      map((response) => {
        const service = response.data.find((item) => item.service_id === serviceId);
        return service?.mnemo;
      })
    );
  }
}
