import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmPluginValidationErrorModule } from './plugin-validation-error/plugin-validation-error.module';
import { registerWebComponent } from '../../@tm-shared/custom-elements';
import { TmPluginValidationErrorComponent } from './plugin-validation-error/plugin-validation-error.component';

import en from './i18n/plugin.en.json';
import ru from './i18n/plugin.ru.json';

@NgModule({
  imports: [TmPluginValidationErrorModule],
})
export class TmPluginsElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
    registerWebComponent('tme-plugin-validation-error', TmPluginValidationErrorComponent);
  }
}
