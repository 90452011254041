import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwIconModule, IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared/tm-shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { en, ru } from '../i18n';
import { TmPaginationModule } from '../pagination';
import { TmToolbarModule } from '../toolbar';
import { BooleanCellComponent, CheckboxCellComponent, ColorCellComponent, DateCellComponent } from './cell-renderers';
import { FullWidthCellComponent } from './cell-renderers/full-width-cell/full-width-cell-renderer';
import { RoutingCellComponent } from './cell-renderers/routing-cell/routing-cell.component';
import { GridFilterDirective } from './grid-filter.directive';
import { TmGridComponent } from './tm-grid.component';
import { GridSelectFilterDirective } from './grid-select-filter.directive';
import { TmLocalizeDateModule } from '../localize-date/localize-date.module';
import { TextWithIconCellComponent } from './cell-renderers/text-with-icon-cell/text-with-icon-cell.component';
import { FileFormatCellComponent } from './cell-renderers/file-format-cell/file-format-cell.component';
import { GridSelectedCountComponent } from './grid-selected-count.component';
import { ErrorTooltipComponent } from './tooltips/error-tooltip/error-tooltip.component';
import { TooltipEllipsisDirective } from './tooltips/error-tooltip/tooltip-ellipsis.directive';

/**
 * TODO: Write tests for grid
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AgGridModule,
    TmToolbarModule,
    TmPaginationModule,
    IwSharedModule,
    CommonModule,
    TranslateModule,
    TmSharedModule,
    RouterModule,
    TmLocalizeDateModule,
    IwIconModule,
  ],
  declarations: [
    TmGridComponent,
    GridFilterDirective,
    GridSelectFilterDirective,
    CheckboxCellComponent,
    BooleanCellComponent,
    RoutingCellComponent,
    ColorCellComponent,
    DateCellComponent,
    FileFormatCellComponent,
    TextWithIconCellComponent,
    FullWidthCellComponent,
    GridSelectedCountComponent,
    ErrorTooltipComponent,
    TooltipEllipsisDirective,
  ],
  exports: [
    TmGridComponent,
    GridFilterDirective,
    GridSelectFilterDirective,
    CheckboxCellComponent,
    BooleanCellComponent,
    RoutingCellComponent,
    ColorCellComponent,
    DateCellComponent,
    FileFormatCellComponent,
    TextWithIconCellComponent,
    FullWidthCellComponent,
    GridSelectedCountComponent,
    ErrorTooltipComponent,
  ],
})
export class TmGridModule {
  constructor(private _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
