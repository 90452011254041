import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { param, paramParse, urlParse } from '@tm-shared/helpers/url';
import { cloneJson } from '../helpers/data';

// @deprecated
@Injectable()
export class UrlStreams<UrlParams = any> {
  /**
   * Base url getter
   */
  public get baseUrl(): string {
    return this._baseUrl$.getValue();
  }

  /**
   * Params getter
   */
  public get params(): AnifyProps<UrlParams> {
    return cloneJson(this._params$.getValue());
  }

  /**
   * Stringified URL params
   */
  public get paramsQueryString(): string {
    return this.getParamsQueryString();
  }

  /**
   * Complete URL getter
   */
  public get url(): string {
    return this.getUrl();
  }
  public params$: Observable<AnifyProps<UrlParams>> = of(null).pipe(switchMap(() => this._params$));

  /**
   * Complete URL stream
   */
  public url$: Observable<string> = of(null)
    .pipe(switchMap(() => combineLatest(this._baseUrl$, this._params$)))
    .pipe(map(([baseUrl, params]) => this._joinBaseUrlAndParams(baseUrl, params)));
  /**
   * Base URL stream
   */
  private _baseUrl$: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * URL params stream
   */
  private _params$: BehaviorSubject<AnifyProps<UrlParams>> = new BehaviorSubject(<UrlParams>{});

  /**
   * Create instance. Alias to (new UrlStrings()).setUrl(url)
   */
  public static create<T = any>(url: string): UrlStreams<T> {
    const instance = new UrlStreams();

    instance.setUrl(url);

    return instance;
  }

  /**
   * Delete param from URL
   */
  public deleteParam(key: keyof UrlParams): void {
    const params = this._params$.getValue();

    delete params[key];
    this._params$.next(params);
  }

  /**
   * Get base url
   */
  public getBaseUrl(): string {
    return this._baseUrl$.getValue();
  }

  /**
   * Get URL param by key
   *
   * Impossible to predict output (string or UrlParams[keyof UrlParams]),
   * so let it be <any>
   */
  public getParam(key: keyof UrlParams): any {
    const params = this._params$.getValue();

    return params[key] ? cloneJson(params[key]) : undefined;
  }

  /**
   * Get all URL params
   */
  public getParams(): AnifyProps<UrlParams> {
    return cloneJson(this._params$.getValue());
  }

  /**
   * Get URL params as a query string
   */
  public getParamsQueryString(): string {
    return this._paramsToQueryString(this._params$.getValue());
  }

  /**
   * Current URL string
   */
  public getUrl(): string {
    return this._joinBaseUrlAndParams(this._baseUrl$.getValue(), this._params$.getValue());
  }

  /**
   * Convert query object to string
   */
  public param(obj: any, prefix?: string): string {
    return param(obj, prefix);
  }

  /**
   * Reset URL params with new ones
   */
  public resetParams(params: UrlParams): void;
  public resetParams(params: string): void;
  public resetParams(params: any) {
    if (typeof params === 'string') {
      params = this._parseParamString(params);
    }

    this._params$.next(params);
  }

  /**
   * Set URL param by key
   */
  public setParam(key: keyof UrlParams, value: string | number | string[]): void {
    const params = this._params$.getValue();

    params[key] = cloneJson(value);

    this._params$.next(params);
  }

  /**
   * Mixin multiple URL params
   */
  public setParams(mixinParams: any): void {
    const params = this._params$.getValue();

    Object.assign(params, cloneJson(mixinParams));
    this._params$.next(params);
  }

  /**
   * Set base url and params with the only string
   */
  public setUrl(url: string): void {
    const [baseUrl, params] = this._parseUrl(url);

    this._baseUrl$.next(baseUrl);
    this.setParams(params);
  }

  public clone(): UrlStreams<UrlParams> {
    return UrlStreams.create<UrlParams>(this.toString());
  }

  public toString(): string {
    return this.getUrl();
  }

  /**
   * Join baseUrl string and params object.
   *
   * @returns URL string
   */
  private _joinBaseUrlAndParams(baseUrl: string, params: UrlParams): string {
    return `${encodeURI(baseUrl)}?${this._paramsToQueryString(params)}`;
  }

  /**
   * Stringify params
   */
  private _paramsToQueryString(params: UrlParams): string {
    return `${this.param(params)}`;
  }

  /**
   * Parse param string
   */
  private _parseParamString(paramStr: string): AnifyProps<UrlParams> {
    return (paramParse(paramStr) as unknown) as AnifyProps<UrlParams>;
  }

  /**
   * Parse URL string
   */
  private _parseUrl(url: string): [string, AnifyProps<UrlParams>] {
    return urlParse(url) as [string, AnifyProps<UrlParams>];
  }
}
