import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { TmGridComponent } from '@tm-shared/grid';
import { AbstractTabComponent } from './abstract-tab-component';
import { getValueByScope } from '../../api-services/functions';
import { ConditionValueObject } from '../../../plugins/events/events.model';
import { ColDef } from 'ag-grid-community';

@Directive({})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TableTabComponent extends AbstractTabComponent implements AfterViewInit {
  public abstract scopeToEmitOnChange: TmApi.search.Scopes;
  @ViewChild('grid') public grid: TmGridComponent<any>;

  public abstract columnDefs: ColDef[];

  public ngAfterViewInit() {
    const startingSelected = this.selected.filter((item) => item.TYPE === this.scopeToEmitOnChange);
    const items = startingSelected.map((item) => item.DATA);
    if (items.length) {
      this.grid.selectById(items);
    }
    this.grid.selectedIds.pipe(takeUntil(this.destroy$)).subscribe((currentlySelectedIds) => {
      const newSelected = currentlySelectedIds.map((id) => {
        const itemFromGrid = this.grid
          .getSelected()
          .find((item) => (item ? item[this.grid.idAttribute] === id : false));
        const previousSelected = startingSelected.find((item) => item.DATA === id);
        return itemFromGrid
          ? getValueByScope(itemFromGrid, this.grid.tableConfig.scopes!).data.mapped
          : (previousSelected as ConditionValueObject);
      });

      const allItems = this.selected.filter((item) => item.TYPE !== this.scopeToEmitOnChange);
      allItems.push(...newSelected);
      this.selectedChange.emit(allItems);
    });
  }
}
