import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { TmPluginAccessRoleType } from 'plugins/settings-access/access-exports';
@Injectable({
  providedIn: 'root',
})
export class TmRoleApiService extends TmCollectionLoader<TmApi.role.CollectionItem> {
  public readonly src = '/api/role';

  public idAttribute = 'ROLE_ID';

  public isModelEditable(role: TmApi.role.CollectionItem | TmApi.role.CollectionItem[]): boolean {
    if (Array.isArray(role)) {
      return role.every((_role) => this.isModelEditable(_role));
    }

    return (
      Boolean(role.EDITABLE !== 0) &&
      role.ROLE_TYPE !== (TmPluginAccessRoleType.Officer as number) &&
      role.ROLE_TYPE !== (TmPluginAccessRoleType.Administrator as number)
    );
  }
}
