import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IwSharedModule } from '@platform/shared';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalConfirmComponent } from './confirm';

import { en, ru } from '../i18n';
import { ModalConfirmWithItemsComponent } from './confirm-with-items';
import { ModalComponent } from './modal';
import { TmSpinnerModule } from '../spinner';

@NgModule({
  imports: [TranslateModule.forChild(), CommonModule, IwSharedModule, TmSpinnerModule],
  declarations: [ModalConfirmComponent, ModalConfirmWithItemsComponent, ModalComponent],
  exports: [ModalConfirmComponent, ModalConfirmWithItemsComponent, ModalComponent],
  entryComponents: [ModalConfirmComponent, ModalConfirmWithItemsComponent, ModalComponent],
})
export class TmModals {
  constructor(private _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
