import { Injectable } from '@angular/core';
import { EVENT_QUERY_COLUMNS_ORDERED } from './events-query-columns-ordered';
import { QueryCreate } from '../../../typings/generated/query-api';
import { InCondition } from 'typings/generated/query';

@Injectable()
export class TmEventsQueryService {
  public getDefaultQueryColumns(): TmPluginEvents.query.ColumnPredefined[] {
    return EVENT_QUERY_COLUMNS_ORDERED;
  }

  public getQueryForSimilar(
    displayName: string,
    protoIds: string[],
    category: InCondition['category'],
    userId: number
  ): QueryCreate {
    return {
      DISPLAY_NAME: displayName,
      GENERATE_UNIQUE_NAME: 1,
      QUERY_TYPE: 'query',
      QUERY: {
        columns: this.getDefaultQueryColumns(),
        mode: 'lite',
        sort: {
          CAPTURE_DATE: 'desc',
        },
        data: {
          link_operator: 'and',
          children: [
            {
              category: 'capture_date',
              value: {
                type: 'none',
              },
            },
            {
              category,
              value: protoIds,
            },
          ],
        },
      },
      PRIVILEGE_TYPE: 2,
      FORCE_PRIVILEGES: 1,
      USER_ID: userId,
      privileges_all: [
        {
          USER_ID: userId,
          ENTITY_TYPE: 'query',
          PRIVILEGE_CODE: 'write',
          ENTITY_ID: '',
        },
        {
          USER_ID: userId,
          ENTITY_TYPE: 'query',
          PRIVILEGE_CODE: 'read',
          ENTITY_ID: '',
        },
      ],
    };
  }

  public getColumnByKey(key: string): TmPluginEvents.query.Column {
    return (
      EVENT_QUERY_COLUMNS_ORDERED.find((col) => col.key === key) || {
        type: 'plugin_attribute',
        key: key,
      }
    );
  }

  public pickSortableColumnsFrom(columns: TmPluginEvents.query.Column[]): TmPluginEvents.query.Column[] {
    return columns.filter((c) => c.sortable);
  }

  /**
   * get columns which can be selected for table view
   */
  public pickColumnsForSelection(columns: TmPluginEvents.query.Column[]): TmPluginEvents.query.Column[] {
    const itemsToRemove = ['TEXT'];
    return columns.filter((item) => !itemsToRemove.includes(item.key));
  }
}
