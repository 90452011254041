import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable({
  providedIn: 'root',
})
export class TmProtectedObjectApiService extends TmCollectionLoader<TmApi.protectedObject.CollectionItem> {
  public readonly src = '/api/protectedDocument';
  public idAttribute = 'DOCUMENT_ID';
}
