import { Injectable } from '@angular/core';
import { IwTreeNode } from '@platform/shared';
import { TmLdapGroupHierarchyApiService } from '@tm-shared/api-services';
import { map, of } from 'rxjs';

@Injectable()
export class GroupTabService {
  constructor(private ldapGroupService: TmLdapGroupHierarchyApiService) {}

  public mapGroupToTreeNode(items: TmApi.ldapGroup.CollectionItem[]) {
    return items.map((item) => {
      const path = item.ID_PATH || item.ID_NAME_PATH.ID_PATH;
      const paths = path.split('\\').filter(Boolean);
      return <IwTreeNode>{
        name: item.DISPLAY_NAME,
        hasChildren: !!item.childsCount,
        data: {
          ID_PATH: path,
        },
        path: paths.join('.'),
        id: item.GROUP_ID,
      };
    });
  }

  public mapDataToPaginated(items: IwTreeNode[]) {
    return {
      data: items,
      totalCount: items.length,
    };
  }

  /**
   * getChildren method for iw-tree getChildren input
   * sets logic for loading of lazy nodes
   */
  public getChildren = (node: IwTreeNode) => {
    if (!node.data?.ID_PATH && !node.data?.ID_NAME_PATH?.ID_PATH) {
      return of({ data: [], totalCount: 0 });
    }
    return this.ldapGroupService
      .get({
        params: {
          grouppath: node.data?.ID_PATH || node.data?.ID_NAME_PATH.ID_PATH,
        },
      })
      .pipe(
        map((response) => this.mapGroupToTreeNode(response.data)),
        map((items) => this.mapDataToPaginated(items))
      );
  };
}
