import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable({
  providedIn: 'root',
})
export class TmLdapPersonApiService extends TmCollectionLoader<TmApi.ldapPerson.CollectionItem> {
  public readonly src = '/api/ldapPerson';

  public idAttribute = 'PERSON_ID';

  public getPersonPhotoByPersonId(personId: string, imageHash?: string): string {
    return `${this.src}/image?person_id=${personId}&_=${imageHash ? imageHash : Date.now().toString()}`;
  }
}
