// Генерируется командой npm run icons:sync  на основе иконок лежащих в папке src/assets/icons/svg

import archiveIcon from '../../assets/icons/svg/archive.svg';
import blocadeIcon from '../../assets/icons/svg/blocade.svg';
import checkingIcon from '../../assets/icons/svg/checking.svg';
import clipboardIcon from '../../assets/icons/svg/clipboard.svg';
import clockIcon from '../../assets/icons/svg/clock.svg';
import closeIcon from '../../assets/icons/svg/close.svg';
import cloudIcon from '../../assets/icons/svg/cloud.svg';
import copyIcon from '../../assets/icons/svg/copy.svg';
import crawlerIcon from '../../assets/icons/svg/crawler.svg';
import createIcon from '../../assets/icons/svg/create.svg';
import createPolicyIcon from '../../assets/icons/svg/createPolicy.svg';
import deleteIcon from '../../assets/icons/svg/delete.svg';
import downloadIcon from '../../assets/icons/svg/download.svg';
import downloadAttachmentIcon from '../../assets/icons/svg/downloadAttachment.svg';
import editIcon from '../../assets/icons/svg/edit.svg';
import exportIcon from '../../assets/icons/svg/export.svg';
import eyeIcon from '../../assets/icons/svg/eye.svg';
import fbIcon from '../../assets/icons/svg/fb.svg';
import forbiddenIcon from '../../assets/icons/svg/forbidden.svg';
import ftpIcon from '../../assets/icons/svg/ftp.svg';
import groupIcon from '../../assets/icons/svg/group.svg';
import helpIcon from '../../assets/icons/svg/help.svg';
import importIcon from '../../assets/icons/svg/import.svg';
import inboxIcon from '../../assets/icons/svg/inbox.svg';
import infoIcon from '../../assets/icons/svg/info.svg';
import keyloggerIcon from '../../assets/icons/svg/keylogger.svg';
import listIcon from '../../assets/icons/svg/list.svg';
import localIcon from '../../assets/icons/svg/local.svg';
import mailbrowserIcon from '../../assets/icons/svg/mailbrowser.svg';
import mailclientIcon from '../../assets/icons/svg/mailclient.svg';
import messageIcon from '../../assets/icons/svg/message.svg';
import nextIcon from '../../assets/icons/svg/next.svg';
import personIcon from '../../assets/icons/svg/person.svg';
import printIcon from '../../assets/icons/svg/print.svg';
import refreshIcon from '../../assets/icons/svg/refresh.svg';
import reloadIcon from '../../assets/icons/svg/reload.svg';
import resourceIcon from '../../assets/icons/svg/resource.svg';
import screenshotIcon from '../../assets/icons/svg/screenshot.svg';
import searchIcon from '../../assets/icons/svg/search.svg';
import statusIcon from '../../assets/icons/svg/status.svg';
import successIcon from '../../assets/icons/svg/success.svg';
import successWarningIcon from '../../assets/icons/svg/successWarning.svg';
import successWhiteIcon from '../../assets/icons/svg/successWhite.svg';
import telegramIcon from '../../assets/icons/svg/telegram.svg';
import triangleIcon from '../../assets/icons/svg/triangle.svg';
import urlIcon from '../../assets/icons/svg/url.svg';
import vkIcon from '../../assets/icons/svg/vk.svg';
import warnIcon from '../../assets/icons/svg/warn.svg';
import warningIcon from '../../assets/icons/svg/warning.svg';
import whatsappIcon from '../../assets/icons/svg/whatsapp.svg';
import workstationIcon from '../../assets/icons/svg/workstation.svg';
export type IconName =
  | 'archive'
  | 'blocade'
  | 'checking'
  | 'clipboard'
  | 'clock'
  | 'close'
  | 'cloud'
  | 'copy'
  | 'crawler'
  | 'create'
  | 'createPolicy'
  | 'delete'
  | 'download'
  | 'downloadAttachment'
  | 'edit'
  | 'export'
  | 'eye'
  | 'fb'
  | 'forbidden'
  | 'ftp'
  | 'group'
  | 'help'
  | 'import'
  | 'inbox'
  | 'info'
  | 'keylogger'
  | 'list'
  | 'local'
  | 'mailbrowser'
  | 'mailclient'
  | 'message'
  | 'next'
  | 'person'
  | 'print'
  | 'refresh'
  | 'reload'
  | 'resource'
  | 'screenshot'
  | 'search'
  | 'status'
  | 'success'
  | 'successWarning'
  | 'successWhite'
  | 'telegram'
  | 'triangle'
  | 'url'
  | 'vk'
  | 'warn'
  | 'warning'
  | 'whatsapp'
  | 'workstation';
export const COMMON_TM_ICONS = {
  archive: archiveIcon,
  blocade: blocadeIcon,
  checking: checkingIcon,
  clipboard: clipboardIcon,
  clock: clockIcon,
  close: closeIcon,
  cloud: cloudIcon,
  copy: copyIcon,
  crawler: crawlerIcon,
  create: createIcon,
  createPolicy: createPolicyIcon,
  delete: deleteIcon,
  download: downloadIcon,
  downloadAttachment: downloadAttachmentIcon,
  edit: editIcon,
  export: exportIcon,
  eye: eyeIcon,
  fb: fbIcon,
  forbidden: forbiddenIcon,
  ftp: ftpIcon,
  group: groupIcon,
  help: helpIcon,
  import: importIcon,
  inbox: inboxIcon,
  info: infoIcon,
  keylogger: keyloggerIcon,
  list: listIcon,
  local: localIcon,
  mailbrowser: mailbrowserIcon,
  mailclient: mailclientIcon,
  message: messageIcon,
  next: nextIcon,
  person: personIcon,
  print: printIcon,
  refresh: refreshIcon,
  reload: reloadIcon,
  resource: resourceIcon,
  screenshot: screenshotIcon,
  search: searchIcon,
  status: statusIcon,
  success: successIcon,
  successWarning: successWarningIcon,
  successWhite: successWhiteIcon,
  telegram: telegramIcon,
  triangle: triangleIcon,
  url: urlIcon,
  vk: vkIcon,
  warn: warnIcon,
  warning: warningIcon,
  whatsapp: whatsappIcon,
  workstation: workstationIcon,
};
