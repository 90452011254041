<div class="pagination" *ngIf="total > 1">
  <button
    [disabled]="!hasPage(current - 1)"
    type="button"
    class="button navButton goToFirstPage"
    (click)="goTo(0)"
    [iwPopover]="popoverTemplate"
    [context]="{
      message: '@tm-shared.pagination.first'
    }"
  ></button>
  <button
    [disabled]="!hasPage(current - 1)"
    type="button"
    class="button navButton goToPrevPage"
    (click)="goTo(current - 1)"
    data-qa="paginationPreviousPage"
    [iwPopover]="popoverTemplate"
    [context]="{
      message: '@tm-shared.pagination.previous'
    }"
  ></button>
  <button
    *ngFor="let i of getNearest(current, limit)"
    type="button"
    class="button goToPage {{ i === current ? 'active' : '' }}"
    (click)="goTo(i)"
    data-qa="paginationPage"
    [iwPopover]="popoverTemplate"
    [context]="{
      message: '@tm-shared.pagination.goto',
      i: i
    }"
  >
    {{ i + 1 }}
  </button>

  <button
    [disabled]="!hasPage(current + 1)"
    type="button"
    class="button navButton goToNextPage"
    (click)="goTo(current + 1)"
    [iwPopover]="popoverTemplate"
    data-qa="paginationNextPage"
    [context]="{
      message: '@tm-shared.pagination.next'
    }"
  ></button>
  <button
    [disabled]="!hasPage(current + 1)"
    type="button"
    class="button navButton goToLastPage"
    (click)="goTo(total - 1)"
    [iwPopover]="popoverTemplate"
    [context]="{
      message: '@tm-shared.pagination.last'
    }"
  ></button>
</div>
<iw-select
  [formControl]="paginationSize"
  [consistentModel]="true"
  *ngIf="paginationSizeItems && paginationSizeItems.length > 1"
  [clearable]="false"
  class="pagination-items"
>
  <iw-select-option *ngFor="let item of paginationSizeItems" [value]="item">{{ item }}</iw-select-option>
</iw-select>

<!-- @translate @tm-shared.pagination.first -->
<!-- @translate @tm-shared.pagination.goto -->
<!-- @translate @tm-shared.pagination.last -->
<!-- @translate @tm-shared.pagination.next -->
<!-- @translate @tm-shared.pagination.previous -->
<ng-template #popoverTemplate let-message="message" let-i="i">
  <span class="popover-style">{{ message | translate: { page: i + 1 } }}</span>
</ng-template>
