import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TmLicense, TmLicenseService } from '../../../@tm-shared/license';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tm-license-notification',
  templateUrl: './license-notification.component.html',
  styleUrls: ['./license-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmLicenseNotificationComponent {
  public options = {
    type: new BehaviorSubject(''),
    text: new BehaviorSubject(''),
  };
  public showOnPage = false;
  private DATE_FORMAT = 'dd-MM-yyyy';
  public TYPES = {
    error: 'error',
    warn: 'warn',
    info: 'info',
    success: 'success',
  } as const;

  constructor(public cd: ChangeDetectorRef, private _licenseService: TmLicenseService, private _t: TranslateService) {
    combineLatest([
      this._licenseService.active$,
      this._licenseService.reserved$,
      this._licenseService.expired$,
    ]).subscribe(([activeLicenses, nextLicenses, inactiveLicenses]) => {
      const lastExpiredLicense = this._getLastExpireLicense(inactiveLicenses, inactiveLicenses[0]);
      const lastActiveLicense = this._getLastExpireLicense(activeLicenses, activeLicenses[0]);
      const earliestNextLicense = this._getFirstStartLicense(nextLicenses, nextLicenses[0]);

      // Есть ли лицензия
      if (activeLicenses.length) {
        // Подходит ли лицензия к концу
        if (lastActiveLicense.isExpireSoon) {
          // Если есть нет резервной лицензии
          if (!nextLicenses.length) {
            this.setData(
              this._t.instant('settings-license.notification.license_expiration', {
                begin: lastActiveLicense.expire_date.toFormat(this.DATE_FORMAT),
              }),
              this.TYPES.warn
            );
          } else if (this.hasUnlicensedPeriod(earliestNextLicense, lastActiveLicense)) {
            this.setData(
              this._t.instant('settings-license.notification.unlicensed_period_with_reserve', {
                begin: lastActiveLicense.expire_date.toFormat(this.DATE_FORMAT),
                end: earliestNextLicense.start_date.minus({ days: 1 }).toFormat(this.DATE_FORMAT),
                nextBegin: earliestNextLicense.start_date.toFormat(this.DATE_FORMAT),
              }),
              this.TYPES.warn
            );
          }
        }
        // Сейчас нелицензионный период
      } else {
        // Если есть закончившаяся лицензия
        if (inactiveLicenses.length) {
          // Если есть резервная лицензия
          if (nextLicenses.length) {
            this.setData(
              this._t.instant('settings-license.notification.unlicensed_period_with_reserve', {
                begin: lastExpiredLicense.expire_date.toFormat(this.DATE_FORMAT),
                end: earliestNextLicense.start_date.minus({ days: 1 }).toFormat(this.DATE_FORMAT),
                nextBegin: earliestNextLicense.start_date.toFormat(this.DATE_FORMAT),
              }),
              this.TYPES.warn
            );
          } else {
            // Если нет резервной лицензия
            this.setData(
              this._t.instant('settings-license.notification.license_expired', {
                end: lastExpiredLicense.expire_date.toFormat(this.DATE_FORMAT),
              }),
              this.TYPES.error
            );
          }
        } else {
          // Если есть резервная лицензия
          if (nextLicenses.length) {
            this.setData(
              this._t.instant('settings-license.notification.no_license_message_with_reserve', {
                next: earliestNextLicense.start_date.toFormat(this.DATE_FORMAT),
              }),
              this.TYPES.error
            );
          } else {
            // Если нет резервной лицензии
            this.setData(this._t.instant('settings-license.notification.no_license_message'), 'error');
          }
        }
      }
    });
  }

  private _getLastExpireLicense(licenses: TmLicense[], defaultValue: TmLicense): TmLicense {
    return licenses.reduce((prev, current) => {
      return prev.expire_date > current.expire_date ? prev : current;
    }, defaultValue);
  }

  private _getFirstStartLicense(licenses: TmLicense[], defaultValue: TmLicense): TmLicense {
    return licenses.reduce((prev, current) => {
      return prev.start_date < current.start_date ? prev : current;
    }, defaultValue);
  }

  public hasUnlicensedPeriod(earliestNextLicense: TmLicense, lastActiveLicense: TmLicense): boolean {
    return earliestNextLicense.start_date.diff(lastActiveLicense.expire_date).valueOf() > 0;
  }

  public setData(text: string, type: keyof typeof this.TYPES) {
    this.options.text.next(text);
    this.options.type.next(type);
    this.showOnPage = true;
    this.cd.detectChanges();
  }
}
