import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IRowNode } from 'ag-grid-community';

type nodeType = { node: IRowNode };

@Component({
  selector: 'iw-checkbox-cell',
  templateUrl: 'checkbox-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxCellComponent implements AgRendererComponent, OnDestroy {
  public params: nodeType;

  public isChecked = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  public agInit(params: nodeType): void {
    this.params = params;
    this.isChecked = !!this.params.node.isSelected();
    this.params.node.addEventListener('rowSelected', this.setRowCheckedState);
  }

  public ngOnDestroy(): void {
    this.params?.node.removeEventListener('rowSelected', this.setRowCheckedState);
  }

  public setRowCheckedState = (event: nodeType) => {
    this.isChecked = !!event.node.isSelected();

    this._cdr.markForCheck();
  };

  public refresh(params: nodeType) {
    this.params = params;

    this.setRowCheckedState(this.params);

    return false;
  }

  public checkboxSelectionChange(data: TmGrid.checkBoxCell.CheckBoxChangeData) {
    this.params.node.setSelected(data.checked, false);
  }
}
