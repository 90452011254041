import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FilterValue {
  field: string;
  value: string | number;
}

/**
 * Сортировки делаются с помощью comparator'ов, в gridOptions
 */
export interface ParsedParams {
  filter?: FilterValue[];
  limit?: number;
  start?: number;
}

export interface GetDataResponse {
  data: any[];
  itemsLengthAfterFilter: number;
}

const DEFAULT_RETRY_LIMIT = 50;
const DEFAULT_RETRY_DELAY = 5;

export abstract class AbstractGridApiAdapter implements TmGrid.grid.ApiService {
  public src = '';
  public abstract idAttribute: string;

  public get = (options?: TmApi.GetOptions): Observable<TmApi.GetArrayResponse<any>> => {
    return this.getWithRetries(DEFAULT_RETRY_DELAY, DEFAULT_RETRY_LIMIT, options);
  };

  public getWithRetries(
    retryDelayMs?: number,
    retryLimit?: number,
    options?: TmApi.GetOptions
  ): Observable<TmApi.GetArrayResponse<any>> {
    const parsedParams = this._parseParams(options);
    return this.getData(parsedParams, retryDelayMs, retryLimit).pipe(
      map((response) => {
        return {
          data: response.data,
          meta: {
            totalCount: response.itemsLengthAfterFilter,
          },
        };
      })
    );
  }

  /**
   * Метод, в котором надо сформировать данные. (Отфильтровать/посортировать).
   */
  protected abstract getData(
    parsedParams: ParsedParams,
    _retryDelayMs?: number,
    _retryLimit?: number
  ): Observable<GetDataResponse>;

  /**
   * Распарсить параметры, которые приходят с запросом от АПИ (от таблицы).
   */
  protected _parseParams(options?: TmApi.GetOptions) {
    let instructions: ParsedParams = {};
    if (options) {
      const params = options.params;
      if (!params) {
        return {};
      }
      Object.keys(params).forEach((param) => {
        if (param.startsWith('filter[')) {
          if (!instructions.filter) {
            instructions.filter = [];
          }
          instructions.filter.push({
            field: param.substring(7, param.length - 1),
            value: params[param].toString(),
          });
        } else if (param === 'limit' || param === 'start') {
          instructions[param] = +params[param];
        }
      });
    }

    return instructions;
  }

  /**
   * Проходит ли значение по указанному фильтру
   * @param value значение из столбца
   * @param toFilter фильтрующее значение
   * @example *example*  or *example or example* or example
   * @return true, если значение проходит по указанному фильтру, false иначе
   */
  protected _valuePassesFilter(value: string, toFilter: string | number): boolean {
    toFilter = toFilter.toString().toLowerCase();
    value = value.toLowerCase();

    if (toFilter.startsWith('*') && toFilter.endsWith('*')) {
      toFilter = toFilter.substring(1, toFilter.length - 1);
      return value.includes(toFilter);
    } else if (toFilter.startsWith('*')) {
      toFilter = toFilter.substring(1);
      return value.endsWith(toFilter);
    } else if (toFilter.endsWith('*')) {
      toFilter = toFilter.substring(0, toFilter.length - 1);
      return value.startsWith(toFilter);
    } else {
      return value === toFilter;
    }
  }

  /**
   * Применить все фильтры
   * @param data фильтруемые данные
   * @param filters список фильтров
   */
  protected _applyAllFilters(data: any[], filters?: FilterValue[]): any[] {
    if (filters) {
      filters.forEach((filter) => {
        data = data.filter((item) => this._valuePassesFilter(item[filter.field], filter.value));
      });
    }
    return data;
  }

  protected _sliceByPage(items: any[], start?: number, limit?: number): any[] {
    start = start ? start : 0;
    limit = start + (limit || items.length);
    return items.slice(start, limit);
  }
}
