import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmOverlayComponent } from './overlay.component';
import { registerWebComponent } from '../custom-elements';

@NgModule({
  imports: [CommonModule],
  declarations: [TmOverlayComponent],
  entryComponents: [TmOverlayComponent],
  exports: [TmOverlayComponent],
})
export class TmOverlayModule {
  constructor() {
    registerWebComponent('tme-overlay', TmOverlayComponent);
  }
}
