import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TmHealthService } from '@tm-shared/health/health.service';
import { TmSharedElementsModule } from '@tm-shared/tm-shared-elements.module';
import { TmSystemInfoComponent } from './components/tm-systeminfo/tm-systeminfo.component';
import { TmCommonErrorsInterceptor } from './tm-errors.interceptor';
import ru from './i18n/tm.ru.json';
import en from './i18n/tm.en.json';
import { TmService } from './tm.service';
import { registerWebComponent } from '../../@tm-shared/custom-elements';

@NgModule({
  imports: [TranslateModule, TmSharedElementsModule],
  providers: [
    TmCommonErrorsInterceptor,
    TranslateService,
    TmHealthService,
    { provide: 'TmHealthService', useExisting: TmHealthService },
  ],
  declarations: [TmSystemInfoComponent],
  bootstrap: [TmSystemInfoComponent],
})
export class TmElementsModule {
  constructor(private translate: TranslateService, tmService: TmService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
    tmService.bootstrapTm();
    registerWebComponent('tme-systeminfo', TmSystemInfoComponent);
  }
}
