import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TmPluginValidationErrorComponent } from './plugin-validation-error.component';
import { TmPluginValidationErrorService } from './plugin-validation-error.service';

@NgModule({
  imports: [CommonModule, TranslateModule],
  providers: [TranslateService, TmPluginValidationErrorService],
  declarations: [TmPluginValidationErrorComponent],
  exports: [TmPluginValidationErrorComponent],
  entryComponents: [TmPluginValidationErrorComponent],
})
export class TmPluginValidationErrorModule {}
