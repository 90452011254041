import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TmAttachmentService } from '../attachment.service';
import { Attachment } from '../attachment.model';
import { TranslateService } from '@ngx-translate/core';
import { TmBookwormFormatsService } from '../../../../@tm-shared/bookworm';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'tm-event-attachment-card',
  templateUrl: 'attachment-card.component.html',
  styleUrls: ['attachment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmEventsAttachmentCardComponent {
  @Input() event: any;

  public dateFormat = 'DDDD HH:mm:ss';
  constructor(
    private _formatsService: TmBookwormFormatsService,
    public attachmentService: TmAttachmentService,
    public translateService: TranslateService
  ) {}

  public getFileName(attachment: Attachment): Observable<string> {
    return this._formatsService.getNameByMime(attachment.MIME ? attachment.MIME : '').pipe(
      map((mimeName) => {
        return attachment.FILE_NAME || mimeName;
      })
    );
  }

  public downloadUrl(attachment: Attachment): string {
    const { OBJECT_ID, OBJECT_CONTENT_ID, TBS_ID } = attachment;
    const params = new URLSearchParams({
      object_id: OBJECT_ID.toString(),
      content_id: OBJECT_CONTENT_ID.toString(),
      tbs_id: TBS_ID.toString(),
      download: '1',
    });

    return `/api/object/content?${params.toString()}`;
  }
}
