import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { TmAttachmentService } from './attachment.service';

@Component({
  selector: 'tm-event-icon-attachment',
  templateUrl: 'attachment.component.html',
  styleUrls: ['attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmEventsAttachmentComponent implements OnInit, OnDestroy {
  public events$: Observable<any[]>;
  public events: any[];

  constructor(public attachmentService: TmAttachmentService) {}

  ngOnInit() {
    // определаяем this.events здесь, а не в onKey* методах с использованием
    // firstValueFrom и await, потому что onKey* методы не могут быть async.
    // Если их сделать async они перестают срабатывать на document,
    // пока не ткнёш мышкой в документ.
    this.events$ = this.attachmentService.getCollection().pipe(tap((events) => (this.events = events)));
  }

  ngOnDestroy() {
    const { QUERY, SELECTED_OBJECT_ID } = this.attachmentService.getIdsFromPageUrl();

    this.attachmentService.tryTmbbNavigation({ QUERY, SELECTED_OBJECT_ID }, { force: false });
  }

  @HostListener('document:keyup.arrowup', ['$event'])
  public onKeyUp() {
    this.attachmentService.navigateToPreviousAttachment(this.events);
  }

  @HostListener('document:keyup.arrowdown', ['$event'])
  public onKeyDown() {
    this.attachmentService.navigateToNextAttachment(this.events);
  }
}
