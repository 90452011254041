import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TmEventIconService } from './tm-event-icon.service';

@Component({
  selector: 'tm-event-icon',
  templateUrl: 'tm-event-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmEventsIconComponent {
  @Input() mnemo: string | undefined;
  @Input() serviceCode: string | undefined;
  constructor(public eventIconService: TmEventIconService) {}
}
