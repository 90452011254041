<div>
  <tm-icon
    class="gridIcon"
    [styles]="{ 'vertical-align': 'top' }"
    *ngIf="data.iconShowCondition && data.icon"
    [svgIcon]="data.icon"
  ></tm-icon>
  <img class="gridIcon" *ngIf="data.iconShowCondition" [src]="data.iconPngPath" />
  <span>{{ data.value }}</span>
</div>
