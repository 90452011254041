import { Injectable } from '@angular/core';
import { EMPTY, ReplaySubject, merge } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TmConfigLocalService } from '../services';
import { TmSessionService } from '../../../@tm-shared/session';
import { ConfigDataGet } from '../generated/config';
import { TmAutolingTrainingService } from 'plugins/tech-autoling/autoling-training.service';
import { TmGraphicTrainingService } from 'plugins/tech-graphic/graphic-training.service';

export interface LocalConfig {
  config: ConfigDataGet;
  isCurrentUser: boolean;
}

@Injectable()
export class TmConfigWidgetService {
  public localConfig$ = new ReplaySubject<LocalConfig>(1);

  constructor(
    private _configLocal: TmConfigLocalService,
    private _session: TmSessionService,
    private _tmAutolingTrainingService: TmAutolingTrainingService,
    private _tmGraphicTrainingService: TmGraphicTrainingService
  ) {}

  public connect(): void {
    this._session.session$.subscribe(() => {
      this._connectWidgetToLocalService();
    });

    merge(this._tmAutolingTrainingService.success$, this._tmGraphicTrainingService.success$).subscribe(() =>
      this._configLocal.refreshConfig()
    );
  }

  private _connectWidgetToLocalService(): void {
    this._configLocal.dataWithUpdates$
      .pipe(
        switchMap((config) => {
          if (!config) {
            return EMPTY;
          }

          return this._session.isCurrentUserById(config.USER_ID).pipe(map((isCurrent) => ({ config, isCurrent })));
        })
      )
      .subscribe(({ config, isCurrent }) => {
        this.localConfig$.next({
          config,
          isCurrentUser: isCurrent,
        });
      });
  }
}
