import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { TmLdapServer } from '@tm-shared/ldap';

@Injectable({
  providedIn: 'root',
})
export class TmAdlibitumServerService extends TmCollectionLoader<TmLdapServer> {
  public readonly src = '/api/adlibitum/server';
  public idAttribute = 'name';
}
