import { Injectable, NgZone, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TmTechLoaderComponentService } from './tech-loader-component.service';
import { TmTechLoaderItem, TmTechLoaderStatus, TmTechLoaderType } from './tech-loader.model';
import { TranslateService } from '@ngx-translate/core';

// TODO:
type SampleCompilerStates = 'running' | 'success' | 'error' | 'upload' | 'compiling' | 'warning';
type Action = 'create' | 'update' | 'export' | 'import';

export interface TmbbUpdateModelState {
  // Const ?
  type: 'fingerprint';
  // Action type
  action: Action;
  // file name
  name: string;
  // Sample compiler states
  state: SampleCompilerStates;
  // File key
  sectionId: string;
  // Group key
  module: 'analysis';
  // Uploaded percent
  percent?: number;
  // File size
  size: number;
  // File type
  fileType: string;
  // ???
  compileType: 'TEXT' | any;
  // ???
  id: string;
  // File key
  key: string;
  // Error text. May contain HTML
  error?: string;
  // Group name
  groupName: string;
}

@Injectable({
  providedIn: 'root',
})
export class TmbbTechLoaderService {
  private groupList: string[] = [];

  constructor(
    private service: TmTechLoaderComponentService,
    private sanitizer: DomSanitizer,
    private _t: TranslateService,
    private zone: NgZone
  ) {}

  public pushUpdate(state: TmbbUpdateModelState): void {
    this.zone.run(() => this.addOrUpdateItem(state));
  }

  public export(type: TmTechLoaderType): void {
    this.zone.run(() => this.service.export(type));
  }

  public import(type: TmTechLoaderType): void {
    this.zone.run(() => this.service.import(type));
  }

  protected getGroupId(state: TmbbUpdateModelState): string {
    const groupId = state.sectionId;

    if (this.groupList.indexOf(groupId) === -1) {
      this.service.initGroup({
        key: groupId,
        items: [],
        i18nKey: state.groupName,
      });
      this.groupList.push(groupId);
    }

    return groupId;
  }

  protected getStatus(state: TmbbUpdateModelState): TmTechLoaderStatus {
    if (state.state === 'warning') {
      return TmTechLoaderStatus.warning;
    }

    if (state.error) {
      return TmTechLoaderStatus.error;
    }

    // TODO: move statuses out somewhere
    if (['success', 'save', 'updated'].includes(state.state)) {
      return TmTechLoaderStatus.success;
    }

    return TmTechLoaderStatus.inProgress;
  }

  protected addOrUpdateItem(state: TmbbUpdateModelState): void {
    const groupId = this.getGroupId(state);
    const status = this.getStatus(state);
    let item: TmTechLoaderItem;
    // для избежания отображения двойной загрузки отображаем только имя файла
    // только для событий типа change в бэкбоновском коде
    if (!state.key) {
      return;
    }
    switch (status) {
      case TmTechLoaderStatus.error:
      case TmTechLoaderStatus.warning:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: '',
          errorPopover: this.sanitizer.sanitize(SecurityContext.HTML, state.error || '') || undefined,
          errorPopoverUseHtml: true,
        };
        break;
      case TmTechLoaderStatus.inProgress:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: state.percent ? `${state.percent || ''}%` : this._getStatusFromState(state.state),
        };
        break;
      case TmTechLoaderStatus.success:
      default:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: '',
        };
    }

    this.service.addItemToGroup(groupId, item);
  }

  private _getStatusFromState(state: string): string {
    return state === 'compiling'
      ? this._t.instant('@tm-shared.techLoader.status.compile')
      : this._t.instant('@tm-shared.techLoader.status.inProgress');
  }
}
