import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { map, switchMap } from 'rxjs/operators';
import { QueryCommon, QueryInfo } from '../../typings/generated/query-api';

@Injectable({
  providedIn: 'root',
})
export class QueryApiService extends TmCollectionLoader<QueryInfo, QueryCommon> {
  public readonly src = '/api/query';
  public idAttribute = 'QUERY_ID';

  public saveAndExecute(data: QueryCommon) {
    return this.create(data).pipe(
      switchMap((response) => this.execute(response.data.QUERY_ID)),
      map((response) => response.data.QUERY_ID)
    );
  }

  public execute(id: string) {
    return this._http.post<TmApi.GetResponse<QueryInfo>>(`${this.src}/${id}/execute`, null);
  }
}
