import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  GuardsCheckEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EMPTY } from 'rxjs';
import { filter } from 'rxjs/operators';

export const RELOAD_URL = '/reload';

@Injectable()
export class TmLegacySupportService implements CanActivate, CanActivateChild {
  constructor(router: Router) {
    this._listenRouterEvents(router);
  }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const URL = state.url;

    if (this._isTmngUrl(URL)) {
      return true;
    }

    location.assign(URL);

    // Do not respond, we should let page reload
    return EMPTY;
  }

  public canActivateChild() {
    return this.canActivate.apply(this, arguments);
  }

  private _listenRouterEvents(router: Router): void {
    router.events.pipe(filter((event) => event instanceof GuardsCheckEnd)).subscribe((event: GuardsCheckEnd) => {
      if (!event.shouldActivate) {
        const nextUrl = event.url;

        // If it's not tmng - kick to backbone
        if (!this._isTmngUrl(nextUrl)) {
          location.assign(nextUrl);
        }
      }
    });
  }

  private _isTmngUrl(url: string): boolean {
    return !!TMNG_ROUTES.find((supportedUrl) => new RegExp(supportedUrl).test(url)) || url === RELOAD_URL;
  }
}
