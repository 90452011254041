import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  AfterViewInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, merge, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMapTo, takeUntil } from 'rxjs/operators';
import { LocalConfig, TmConfigWidgetService } from './config-widget.service';
import { SandboxStatus, Status, ProcessingStatus } from '../generated/config';
import { IwModalService, IwPopoverOptions } from '@platform/shared';
import { Mode, TmConfigModalComponent } from '../config-modal/config-modal.component';
import { getFlagsByConfig } from '../common';
import { ModalComponent } from '@tm-shared/modals/modal';
import { TmPrivilegesService } from '@tm-shared/privileges';

const statusToKey = {
  [Status.FREE]: 'default',
  [Status.LOCKED]: 'locked',
  [Status.SAVED]: 'saved',
  [Status.NEEDS_TRAINING]: 'needs_training',
  [Status.IS_TRAINING]: 'is_training',
};

@Component({
  selector: 'tm-config-widget',
  templateUrl: './config-widget.component.html',
  styleUrls: ['./config-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TmConfigWidgetComponent implements AfterViewInit, OnDestroy {
  @Input() public localConfig?: LocalConfig;

  @Output() public fetchQueued: EventEmitter<void> = new EventEmitter();

  public showOnPage = false;

  public showButtons: boolean;
  public showRevertButton: boolean;
  public showUsername: boolean;
  public showLastEdited: boolean;
  public disableRevert: boolean;
  public disableRevertReason: string;
  public disableApply: boolean;
  public disableApplyReason: string;
  public hideSaveButton: boolean;
  public inProgress: boolean;
  public processing: boolean;
  public showWarning: boolean;

  public applyingConfigurationStatusText: string;
  public localConfigurationStatusText: string;
  public timer: string;

  public username: string;
  public training: string;
  public lastEdited: string;

  private _updateConfiguration$ = new Subject<void>();
  private _destroy$ = new Subject<void>();

  constructor(
    private _t: TranslateService,
    public cd: ChangeDetectorRef,
    private _service: TmConfigWidgetService,
    private modalService: IwModalService,
    private _privilegesService: TmPrivilegesService
  ) {}

  public ngOnDestroy() {
    this._updateConfiguration$.next();
    this._updateConfiguration$.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngAfterViewInit() {
    this._listenToService();
    //  TODO: angular-way по выезду из кофе
    timer(0, 50)
      .pipe(
        map(() => this.getShowOnPageFlag()),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((show) => {
        this.showOnPage = show;
        this.cd.detectChanges();
      });
  }

  private getShowOnPageFlag() {
    return (
      location.href.includes('protected') ||
      location.href.includes('analysis') ||
      location.href.includes('policy') ||
      location.href.includes('lists') ||
      location.href.includes('notification') ||
      location.href.includes('organization')
    );
  }

  public popoverVisibilityChanged(_visible: boolean): void {
    this.cd.detectChanges();
  }

  public openConfigModal(mode: Mode, processing: boolean) {
    this.modalService.open(TmConfigModalComponent, { mode, processing });
    this.cd.detectChanges();
  }

  public async openWarningModal() {
    const modal = await this.modalService.open(ModalComponent, {
      title: this._t.instant('config.modal.warning.title'),
      text: this._t.instant('config.modal.warning.text'),
      color: '',
      buttons: [
        {
          click: () => {
            modal.dismiss(undefined);
          },
          text: this._t.instant('config.modal.warning.ok'),
          color: 'success',
        },
      ],
    });
    this.cd.detectChanges();
  }

  public formatTimestamp(timestamp: number): string {
    return DateTime.fromMillis(timestamp).toFormat('D T');
  }

  private _listenToService() {
    const localConfig$ = this._service.localConfig$;
    const canResetConfig$ = this._privilegesService.can({
      type: 'configuration',
      action: 'revert',
    });

    combineLatest([merge(localConfig$, this._t.onLangChange.pipe(switchMapTo(localConfig$))), canResetConfig$])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([data, canReset]) => {
        this.localConfig = data;
        if (!this.localConfig) {
          return;
        }

        this._updateApplyingStatusText(this.localConfig);
        this._updateConfigStatusText(this.localConfig);
        this._updateNeedsTrainingText(this.localConfig);

        const obj = getFlagsByConfig(
          this.localConfig.config,
          this._t,
          canReset,
          this.localConfig.isCurrentUser,
          Boolean(this.localConfig.config.user?.DISPLAY_NAME)
        );
        this.showButtons = obj.showButtons;
        this.showRevertButton = obj.showRevertButton;
        this.disableRevert = obj.disableRevert;
        this.disableRevertReason = obj.disableRevertReason;
        this.disableApply = obj.disableApply;
        this.disableApplyReason = obj.disableApplyReason;
        this.hideSaveButton = obj.hideSaveButton;
        this.inProgress = obj.inProgress;
        this.processing = this.localConfig.config.PROCESSING_STATUS === ProcessingStatus.COMMITING;
        this.showUsername = obj.showUsername;
        this.showLastEdited = obj.showLastEdited;

        this.cd.detectChanges();
      });
  }

  private _updateApplyingStatusText(localConfig: LocalConfig) {
    this._updateConfiguration$.next();
    timer(0, 1000)
      .pipe(
        map(() => DateTime.fromSQL(localConfig.config.SEND_DATE!, { zone: 'utc' })),
        distinctUntilChanged(),
        takeUntil(this._updateConfiguration$),
        takeUntil(this._destroy$)
      )
      .subscribe((changeDate: DateTime) => {
        if (localConfig.config.PROCESSING_STATUS) {
          const date = DateTime.now().diff(changeDate);
          const hours = date.shiftTo('hours').hours;
          const days = Math.floor(date.shiftTo('days').days);
          const time = date.minus({ days: days }).toFormat('hh:mm:ss');
          const timer = Boolean(days) ? `${days}${this._t.instant('config.widget.day')} ${time}` : time;
          this.showWarning = Boolean(hours >= 3);
          this.applyingConfigurationStatusText = this._t.instant('config.widget.title.applying');
          this.timer = `${timer} `;
        } else {
          this.applyingConfigurationStatusText = '';
          this.timer = '';
        }
        this.cd.detectChanges();
      });
  }

  private _updateConfigStatusText(localConfig: LocalConfig) {
    const date = DateTime.fromSQL(localConfig.config.CHANGE_DATE!, { zone: 'utc' }).toLocal().toFormat('DD HH:mm');
    this.lastEdited = this._t.instant(`config.widget.title.lastEdited`, { date });

    if (
      localConfig.isCurrentUser &&
      (SandboxStatus.LOCKED === localConfig.config.SANDBOX_STATUS || Status.LOCKED === localConfig.config.STATUS)
    ) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.editedByActiveUser`);
      this.username = '';
      return;
    }

    if (
      localConfig.isCurrentUser &&
      (localConfig.config.SANDBOX_STATUS === SandboxStatus.SAVED || localConfig.config.STATUS === Status.SAVED)
    ) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.savedByActiveUser`);
      this.username = '';
      return;
    }

    if (!localConfig.isCurrentUser && localConfig.config.SANDBOX_STATUS === SandboxStatus.SAVED) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.saved`);
      this.username = localConfig.config.user?.DISPLAY_NAME;
      return;
    }

    let status = localConfig.config.STATUS;
    if (!localConfig.isCurrentUser && (status === Status.NEEDS_TRAINING || status === Status.IS_TRAINING)) {
      status = Status.LOCKED;
    }

    if (status === Status.IS_TRAINING || localConfig.config.PROCESSING_STATUS === ProcessingStatus.COMMITING) {
      this.localConfigurationStatusText = '';
      this.username = '';
      return;
    }

    // @translate config.widget.title.saved
    // @translate config.widget.title.locked
    // @translate config.widget.title.default
    if (localConfig.config.user) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.${statusToKey[status]}`);
      this.username = localConfig.config.user?.DISPLAY_NAME;
    }
  }

  private _updateNeedsTrainingText(localConfig: LocalConfig) {
    if (localConfig.config.STATUS === Status.IS_TRAINING) {
      // @translate config.widget.title.image_classifier
      // @translate config.widget.title.autoling
      const tech = this._t.instant(`config.widget.title.${localConfig.config.TECHNOLOGY}`);
      this.training = this._t.instant('config.widget.title.is_training', { tech });
      return;
    }
    this.training = '';
  }
}
