<tm-icon
  *ngIf="mnemo && eventIconService.getEventIconName(mnemo) as iconName; else fallbackImage"
  [svgIcon]="iconName"
  [size]="'22'"
></tm-icon>
<ng-template #fallbackImage>
  <img
    [src]="eventIconService.getIconUrl(mnemo)"
    (error)="eventIconService.getFallbackImageUrl($event, serviceCode)"
    width="20"
    height="20"
  />
</ng-template>
