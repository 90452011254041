import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { CategoryListItem } from '../../typings/generated/technology-category';
import { IwTreeNode } from '@platform/shared';

export type CategoryNodeData = {
  protectedDocuments: string[];
  description: string;
  isSystem: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CategoryApiService extends TmCollectionLoader<CategoryListItem> {
  public readonly src = '/api/category';
  public idAttribute = 'CATEGORY_ID';

  public convertListToTree(
    data: CategoryListItem[],
    pid: string | undefined = undefined
  ): IwTreeNode<CategoryNodeData>[] {
    const treeData: IwTreeNode[] = [];
    data.reduce((prevCategory, category) => {
      if (category.PARENT_CATEGORY_ID === pid) {
        treeData.push({
          id: category.CATEGORY_ID,
          name: category.DISPLAY_NAME,
          children: this.convertListToTree(data, category.CATEGORY_ID),
          data: {
            protectedDocuments: category.protected_documents?.map((item) => item.DISPLAY_NAME),
            description: category.NOTE,
            isSystem: category.IS_SYSTEM,
          },
        });
      }
      return prevCategory;
    }, []);

    return treeData;
  }
}
