import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'tm-person-tab',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonTabComponent extends TableTabComponent {
  public static scope = 'person' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.person';

  public scopeToEmitOnChange = PersonTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: this.scopeToEmitOnChange,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public columnDefs: ColDef[] = [
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      resizable: true,
      sort: 'asc',
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.full_name'),
    },
    {
      field: 'SOURCE',
      resizable: true,
      sortable: true,
      width: 100,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.source'),
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === 'fi') {
            return 'A/F';
          } else {
            return params.value.toUpperCase();
          }
        } else {
          return '';
        }
      },
    },
    {
      field: 'ACCOUNT',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.mail'),
      valueGetter: (params: ValueGetterParams) => {
        const data = params.data;
        if (!data) {
          return '';
        }

        let auth = data.contacts?.find((item: any) => item.CONTACT_TYPE === 'auth');
        auth = auth?.VALUE;

        if (auth) {
          return auth;
        }

        if (data.SOURCE === 'dd' || data.LOTUS) {
          return data.LOTUS;
        }

        return '';
      },
    },
    {
      resizable: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.real_server && params.data.real_server.DISPLAY_NAME;
      },
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.ldapServer'),
    },
  ];
}
