import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { AgGridModule } from 'ag-grid-angular';
import { ExportMonitorCellDateComponent } from './components/export-monitor-list-cell-date/export-monitor-list-cell-date.component';
import { ExportMonitorCellDeleteComponent } from './components/export-monitor-list-cell-delete/export-monitor-list-cell-delete.component';
import { ExportMonitorCellStatusComponent } from './components/export-monitor-list-cell-status/export-monitor-list-cell-status.component';
import { ExportMonitorListComponent } from './components/export-monitor-list/export-monitor-list.component';
import { TmExportMonitorComponent } from './components/export-monitor/export-monitor.component';
import { TmEventSearchWidgetComponent } from './events-search-widget/events-search-widget.component';
import { TmEventsSearchWidgetService } from './events-search-widget/events-search-widget.service';
import { TmEventsService } from './events.service';
import { TmEventsQueryService } from './query/events-query.service';
import ru from './i18n/events.ru.json';
import en from './i18n/events.en.json';
import { LuxonModule } from 'luxon-angular';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';
import { TmSpinnerModule } from '../../@tm-shared/spinner';
import { TmLocalizeDateModule } from '../../@tm-shared/localize-date/localize-date.module';
import { TmExportMonitorService } from './components/export-monitor/export-monitor.service';
import { ExportMonitorCellNameComponent } from './components/export-monitor-list-cell-name/export-monitor-list-cell-name.component';
import { registerWebComponent } from '../../@tm-shared/custom-elements';
import { TmEventsAttachmentComponent } from './attachment/attachment.component';
import { TmAttachmentService } from './attachment/attachment.service';
import { TmEventsAttachmentCardComponent } from './attachment/attachment-card/attachment-card.component';
import { TmEventIconModule } from '../../@tm-shared/event-icon/tm-event-icon.module';
import { TmEventsTypeIconComponent } from './components/event/tm-event-type-icon.component';

@NgModule({
  imports: [
    CommonModule,
    IwSharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LuxonModule,
    TmIconModule,
    AgGridModule,
    TmSpinnerModule,
    TmLocalizeDateModule,
    TmEventIconModule,
  ],
  declarations: [
    TmEventsAttachmentCardComponent,
    TmEventSearchWidgetComponent,
    TmExportMonitorComponent,
    TmEventsAttachmentComponent,
    TmEventsTypeIconComponent,
    ExportMonitorListComponent,
    ExportMonitorCellStatusComponent,
    ExportMonitorCellDeleteComponent,
    ExportMonitorCellDateComponent,
    ExportMonitorCellNameComponent,
  ],
  entryComponents: [TmEventSearchWidgetComponent, TmExportMonitorComponent, TmEventsAttachmentComponent],
  providers: [
    TmEventsService,
    TmEventsSearchWidgetService,
    TmEventsQueryService,
    TmAttachmentService,
    TmExportMonitorService,
    { provide: 'TmExportMonitorService', useExisting: TmExportMonitorService },
    { provide: 'TmEventsService', useExisting: TmEventsService },
    { provide: 'TmEventsSearchWidgetService', useExisting: TmEventsSearchWidgetService },
    { provide: 'TmEventsQueryService', useExisting: TmEventsQueryService },
    { provide: 'TmAttachmentService', useExisting: TmAttachmentService },
  ],
})
export class TmEventsElementsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('ru', ru, true);
    this._translate.setTranslation('en', en, true);
    registerWebComponent('tme-events-search-widget', TmEventSearchWidgetComponent);
    registerWebComponent('tme-export-monitor', TmExportMonitorComponent);
    registerWebComponent('tme-events-attachment', TmEventsAttachmentComponent);
    registerWebComponent('tme-event-type-icon', TmEventsTypeIconComponent);
  }
}
