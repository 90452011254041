import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { TmConfigLocalService } from '../../plugins/config/services';
import { TmLicenseService } from '../license';
import {
  Privilege,
  TmAccessRequest,
  TmModuleRequest,
  TmPrivilegeMapItem,
  TmPrivilegeMapItemValue,
  TmPrivilegeModel,
  isTmActionRequest,
  isTmModuleRequest,
  privilegesDisabledByConfiguration,
} from './tm-privileges.model';
import { TranslateService } from '@ngx-translate/core';

// Import privileges static file
// @ts-ignore
import PRIVILEGES from 'assets/privileges.json';

/**
 * If action is undefined, this one is used
 */
const DEFAULT_ACTION = 'show';
export const ERROR_KEYS = {
  NOT_ENOUGH_PRIVILEGES: 'not_enough_privileges',
  NOT_LICENSED: 'not_licensed',
  CONFIGURATION_LOCKED: 'configuration_locked',
};

export class PrivilegeError extends Error {
  constructor(text: string) {
    super(text);
    Object.setPrototypeOf(this, PrivilegeError.prototype);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TmPrivilegesService {
  /**
   * Privilege map
   */
  public readonly privilegeMap: TmPrivilegeMapItem[] = this._createFullPrivilegeMap(PRIVILEGES);

  private _privileges$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  private _canStreamCache: { [key: string]: Observable<boolean | PrivilegeError> } = {};

  constructor(
    private _licenseManager: TmLicenseService,
    private _configLocal: TmConfigLocalService,
    private _t: TranslateService
  ) {}

  public resetPrivileges(privileges: TmPrivilegeModel[]): void {
    this._updatePrivilegesState(privileges);
  }

  public getAccessStatus(accessRequest: TmAccessRequest): Observable<boolean | PrivilegeError> {
    const privilege: string = this._parseAccessRequest(accessRequest);
    const cachedStream: Observable<boolean | PrivilegeError> = this._canStreamCache[privilege];

    if (!privilege) {
      return of(true);
    }

    if (cachedStream) {
      return cachedStream;
    }

    this._canStreamCache[privilege] = combineLatest([
      this.getPrivilegeAccessStream(accessRequest),
      this._licenseManager.getLicenseAccessStream(accessRequest),
      this._configLocal.configurationAllowsEdit$,
    ]).pipe(
      map(([privilegeAvailable, licenseAvailable, configurationAllowsEdit]): boolean | PrivilegeError => {
        if (!licenseAvailable) {
          return new PrivilegeError(ERROR_KEYS.NOT_LICENSED);
        }

        if (!privilegeAvailable) {
          return new PrivilegeError(ERROR_KEYS.NOT_ENOUGH_PRIVILEGES);
        }

        const privilegeDependsFromConfig = !!privilegesDisabledByConfiguration.find((dependentPrivilege) =>
          privilege.match(dependentPrivilege)
        );
        if (!configurationAllowsEdit && privilegeDependsFromConfig) {
          return new PrivilegeError(ERROR_KEYS.CONFIGURATION_LOCKED);
        }

        return true;
      })
    );

    return this._canStreamCache[privilege];
  }

  public can(accessRequest: TmAccessRequest): Observable<boolean> {
    return this.getAccessStatus(accessRequest).pipe(
      map((accessStatus: boolean | PrivilegeError): boolean => {
        if (accessStatus instanceof PrivilegeError) {
          return false;
        }
        return accessStatus;
      }),
      shareReplay(1)
    );
  }

  public getPrivilegeTreeFor(parentKey: string | null, fn = (item: any) => item): Observable<any[]> {
    return combineLatest(
      this.privilegeMap
        // Pick items with correct parent key
        .filter((privilege) => (!privilege.parent && !parentKey) || privilege.parent === parentKey)
        .map((privilege: TmPrivilegeMapItem) => this._getPrivilegeTreeChild(privilege, fn))
    ).pipe(map((tree) => tree.filter((i) => !!i)));
  }

  public getPrivilegeAccessStream(accessRequest: TmAccessRequest): Observable<boolean> {
    const requestedPrivilege: string = this._parseAccessRequest(accessRequest);

    return this._privileges$.pipe(
      map((privileges: string[]): boolean => this._checkKeyInPrivilegeList(requestedPrivilege, privileges))
    );
  }

  public updateBySocketMessage(socketMessage: any): void {
    this._updatePrivilegesState(socketMessage.privileges);
  }

  public localizePrivilegeByKey(id: string): string {
    // Для динамической генерации ключей используется privilege-util.js
    return this._t.instant(`settings-access.privileges.${id}`);
  }

  private _getPrivilegeTreeChild(
    privilege: TmPrivilegeMapItem,
    fn = (item: any) => item
  ): Observable<Partial<TmPrivilegeMapItem> | null> {
    const privilegeLicenseIsOk: Observable<boolean> = this._checkPrivilegeLicense(privilege);
    const privilegeChildren = privilege.children ? this.getPrivilegeTreeFor(privilege.id, fn) : of(null);

    return combineLatest([privilegeLicenseIsOk, privilegeChildren]).pipe(
      map(([licensed, children]) => {
        if (!licensed) {
          return null;
        }

        const result: Partial<TmPrivilegeMapItem> = {
          id: privilege.id,
          key: privilege.key,
          parent: privilege.parent,
          value: privilege.value,
        };

        if (children) {
          result.children = children;
        }

        return fn(result);
      })
    );
  }

  private _checkPrivilegeLicense(privilege: TmPrivilegeMapItem): Observable<boolean> {
    if (!privilege.requiredLicenses) {
      return of(true);
    }

    // Check all required licenses are active
    return this._licenseManager.isActiveTechnologyFeature(privilege.requiredLicenses).pipe(
      take(1),
      map((r) => Object.values(r).every((v) => v))
    );
  }

  private _updatePrivilegesState(privileges: TmPrivilegeModel[] | string): void {
    // Clean cache
    this._canStreamCache = {};

    // Parse privileges
    const simplifiedPrivileges: string[] = [];
    for (let privilegeIndex = 0; privilegeIndex < privileges.length; privilegeIndex++) {
      simplifiedPrivileges.push(
        typeof privileges === 'string'
          ? this._simplifyPrivilegeKey(privileges[privilegeIndex])
          : this._parseServerPrivilege(privileges[privilegeIndex])
      );
    }

    // Push privilege list
    this._privileges$.next(simplifiedPrivileges);
  }

  /**
   * Check if key allowed by any privilege
   */
  private _checkKeyInPrivilegeList(key: string, privileges: string[]): boolean {
    for (let privilegeIndex = 0; privilegeIndex < privileges.length; privilegeIndex++) {
      if (this._checkKeyInPrivilege(key, privileges[privilegeIndex])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Check if key is allowed by privilege
   */
  private _checkKeyInPrivilege(key: string, privilege: string): boolean {
    // Privilege should start with key
    if (privilege.indexOf(key) === 0) {
      // 'access/user' should not pass towards 'access/user_role' (for example)
      return privilege[key.length] === undefined || privilege[key.length] === '/' || privilege[key.length] === ':';
    }

    return false;
  }

  private _parseAccessRequest(accessRequest: TmAccessRequest): string {
    let privelegeKey: string;

    if (typeof accessRequest === 'string') {
      privelegeKey = accessRequest;
    } else if (isTmActionRequest(accessRequest)) {
      privelegeKey = `${accessRequest.type}:${accessRequest.action || DEFAULT_ACTION}`;
    } else if (isTmModuleRequest(accessRequest)) {
      privelegeKey = this._parseRouteRequest(accessRequest);
    } else {
      throw new TypeError(`Access request ${accessRequest} must have module or type parameter`);
    }

    return this._simplifyPrivilegeKey(privelegeKey);
  }

  /**
   * Parse module request to privilege code:
   * 'settings/access' => 'settings:access:'
   */
  private _parseRouteRequest(privilege: TmModuleRequest): string {
    return privilege.module.split('/').join(':');
  }

  private _createFullPrivilegeMap(privileges: Privilege): TmPrivilegeMapItem[] {
    return this._fixDependsArraysWithProperValues(this._privilegeTreeToMap(privileges));
  }

  /**
   * depends array may contain not full paths to attributes
   * this case is resolved by regexp search ( "".match )
   */
  private _fixDependsArraysWithProperValues(privilegeMap: TmPrivilegeMapItem[]): TmPrivilegeMapItem[] {
    privilegeMap.forEach((mapItem) => {
      if (mapItem.value && mapItem.value.depends) {
        const realDependencies: string[] = [];
        mapItem.value.depends.forEach((dependItem) => {
          const dependencies = privilegeMap
            .filter((privMapItem) => privMapItem.id.match(dependItem))
            .map((item) => item.id);
          realDependencies.push(...dependencies);
        });
        mapItem.value.depends = realDependencies;
      }
    });
    return this._transformDependsToDependency(privilegeMap);
  }

  /**
   *  iterating depends array values, and setting to corresponding nodes this node as dependency.
   *  So each node has array of nodes, which this node must set if activated.
   *  In opposite to previous technique (each node had a list of nodes, which this node was dependent from)
   */
  private _transformDependsToDependency(privilegeMap: TmPrivilegeMapItem[]): TmPrivilegeMapItem[] {
    privilegeMap.forEach((mapItem) => {
      if (mapItem.value && mapItem.value.depends) {
        mapItem.value.depends.forEach((dependsItem) => {
          if (mapItem.id !== dependsItem) {
            const itemToAddDependencies = privilegeMap.find((innerMapItem) => innerMapItem.id === dependsItem)!;

            if (!itemToAddDependencies.value) {
              itemToAddDependencies.value = { dependencies: [] } as TmPrivilegeMapItemValue;
            } else if (!itemToAddDependencies.value.dependencies) {
              itemToAddDependencies.value.dependencies = [];
            }

            itemToAddDependencies.value.dependencies!.push(mapItem.id);
          }
        });
        delete mapItem.value.depends;
      }
    });
    return privilegeMap;
  }

  private _privilegeTreeToMap(privileges: Privilege, parentKey?: string): TmPrivilegeMapItem[] {
    return Object.keys(privileges).reduce((result, key) => {
      return result.concat(this._parsePrivilegeForMap(key, privileges[key], parentKey));
    }, [] as TmPrivilegeMapItem[]);
  }

  private _parsePrivilegeForMap(rawKey: string, value: any, parentKey = ''): TmPrivilegeMapItem[] {
    const mod: string = rawKey[0];
    const key: string = ['.', '+', '-'].indexOf(mod) === -1 ? rawKey : rawKey.slice(1);
    const pathArr: string[] = [parentKey, key].filter((x) => !!x);
    let result: any[] = [];
    const isRoot = !parentKey.length;
    const isLeaf: boolean = ['+', '-'].indexOf(mod) === -1;

    // Skip trash entries in root
    if (isRoot && isLeaf) {
      return result;
    }

    const privilege: any = {
      key: key,
    };

    // replace 'scope' keyword with current scope
    if (value && value.depends) {
      const scopeIndex = value.depends.indexOf('scope');
      if (scopeIndex !== -1) {
        value.depends.splice(scopeIndex, 1, parentKey);
      }
    }

    if (!isLeaf) {
      const path = pathArr.join('/');
      const children = this._privilegeTreeToMap(value, path);

      result = result.concat(children);
      Object.assign(privilege, {
        id: path,
        children: children.filter((child) => child.parent === path).map((child) => child.id),
      });
    } else {
      const path = pathArr.join(':');
      Object.assign(privilege, {
        id: path,
        value: value,
        parent: parentKey,
      });
    }

    if (!isRoot) {
      privilege.parent = parentKey;
    }

    result.push(privilege);
    return result;
  }

  /**
   * Ensure there is no slashes
   */
  private _simplifyPrivilegeKey(privilege: string): string {
    return privilege.split('/').join(':');
  }

  private _parseServerPrivilege(privilege: TmPrivilegeModel): string {
    return this._simplifyPrivilegeKey(privilege.PRIVILEGE_CODE);
  }
}
