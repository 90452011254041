import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TmBookwormFormatsService } from '../../bookworm';
import { IwBytesWithUnitPipe } from '@platform/shared';
import { FingerprintTabComponent } from './fingerprint-tab.component';

@Component({
  selector: 'tm-stamp-tab',
  templateUrl: './fingerprint-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StampTabComponent extends FingerprintTabComponent {
  public static scope = 'stamp' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.stamps';

  public scopeToEmitOnChange = StampTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: StampTabComponent.scope,
  };

  constructor(
    service: TmSearchService,
    t: TranslateService,
    formatsService: TmBookwormFormatsService,
    bytesWithUnitPipe: IwBytesWithUnitPipe,
    cd: ChangeDetectorRef
  ) {
    super(service, t, formatsService, bytesWithUnitPipe, cd);
  }
}
