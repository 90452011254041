import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { FormatInfo } from '../../typings/generated/bookworm';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TmBookwormFormatsService extends TmStatefulService<TmApi.GetResponse<FormatInfo[]>> {
  public src = '/api/bookworm/formats';

  public getNameByMime(mime: string): Observable<string> {
    return this.sharedData.pipe(
      map((response) => {
        return response.data.find((item) => item.mime_type === mime)?.name || mime;
      })
    );
  }
}
