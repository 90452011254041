import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { TmPersonSearchModel } from '@tm-shared/person-search';

@Injectable({
  providedIn: 'root',
})
export class TmLdapUserSearchService extends TmCollectionLoader<TmPersonSearchModel> {
  public readonly src = '/api/ldap/userSearch';
  public idAttribute = 'OBJECTGUID';
}
