<iw-modal-header>{{ title }}</iw-modal-header>
<tm-spinner class="modalSpinner" *ngIf="showSpinner | async; else modalContent"></tm-spinner>
<ng-template #modalContent>
  <div class="modalText">{{ text }}</div>
  <iw-modal-footer *ngIf="buttons">
    <button
      iw-button
      *ngFor="let button of buttons"
      [iwPopover]="getPopoverText(button)"
      [triggers]="'mouseenter:mouseleave'"
      [disableClickEventOnly]="getDisabledStatus(button)"
      [color]="button.color"
      (click)="button.click()"
    >
      {{ button.text }}
    </button>
  </iw-modal-footer>
</ng-template>
