<iw-modal-header>{{ title }}</iw-modal-header>
<div>
  {{ text }}:
  <p>
    <b *ngIf="preparedItemsToDisplay">
      {{ preparedItemsToDisplay }}
    </b>
  </p>
</div>
<iw-modal-footer>
  <button iw-button color="success" data-qa="confirmModalYES" (click)="accept()">{{ yes }}</button>
  <button iw-button data-qa="confirmModalNO" (click)="reject()">{{ no }}</button>
</iw-modal-footer>
