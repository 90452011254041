import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable({
  providedIn: 'root',
})
export class TmLdapGroupHierarchyApiService extends TmCollectionLoader<TmApi.ldapGroup.CollectionItem> {
  public readonly src = '/api/ldapGroupHierarchy';
  public idAttribute = 'GROUP_ID';
}
