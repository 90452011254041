import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmFormElementService } from './form-elements.service';
import { TmInputManyComponent } from './input-many/input-many.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMaskDirective } from './masked-input/input-mask.directive';
import { registerWebComponent } from '../custom-elements';
import { TmInputRangeComponent } from './input-range/input-range.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [TmInputManyComponent, InputMaskDirective, TmInputRangeComponent],
  providers: [TmFormElementService, { provide: 'TmFormElementService', useExisting: TmFormElementService }],
  exports: [InputMaskDirective, TmInputRangeComponent],
})
export class TmFormElementsModule {
  constructor() {
    registerWebComponent('tme-input-many', TmInputManyComponent);
  }
}
