import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwSharedModule, IwIconService } from '@platform/shared';
import { TmFormElementsModule } from './form-elements/form-elements.module';
import { TmPolicyModule } from './policy/policy.module';
import { TmPreComponent } from './pre/pre.component';
import ru from './i18n/tm-shared.ru.json';
import en from './i18n/tm-shared.en.json';
import { COMMON_TM_ICONS } from '../typings/generated/icons';
import { registerWebComponent } from './custom-elements';
import { NAMED_PROVIDERS } from './providers-for-coffee';
import { QueryApiService } from './api-services/query-api.service';

@NgModule({
  declarations: [TmPreComponent],
  imports: [TmFormElementsModule, IwSharedModule, TmPolicyModule],
  providers: [
    {
      provide: 'QueryApiService',
      useExisting: QueryApiService,
    },
  ],
})
export class TmSharedElementsModule {
  constructor(private translate: TranslateService, private _iconService: IwIconService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);

    this._registerSvgIcons(COMMON_TM_ICONS);
    registerWebComponent('tme-pre', TmPreComponent);
  }

  public static forRoot(): ModuleWithProviders<TmSharedElementsModule> {
    return {
      ngModule: TmSharedElementsModule,
      providers: NAMED_PROVIDERS,
    };
  }

  private _registerSvgIcons(iconMap: { [iconName: string]: string }): void {
    Object.keys(iconMap).forEach((iconName) => {
      this._iconService.addSvgIcon(iconName, iconMap[iconName]);
    });
    this._iconService.addSvgIcon('expander', COMMON_TM_ICONS.next);
  }
}
