import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Attachment, ATTACHMENT_FILTER } from './attachment.model';
import { QueryInfo } from '../../../typings/generated/query-api';
import { WINDOW } from '../events-tokens';
import { param, paramParse } from '@tm-shared/helpers/url';

@Injectable()
export class TmAttachmentService {
  constructor(@Inject(WINDOW) public window: Window) {}

  private _collection$: Subject<QueryInfo[]> = new Subject<any[]>();
  private _filter: ATTACHMENT_FILTER;

  public getIdsFromPageUrl() {
    const { OBJECT_CONTENT_ID, QUERY, SELECTED_OBJECT_ID } = paramParse(this.window.location.search);
    return {
      OBJECT_CONTENT_ID: +OBJECT_CONTENT_ID,
      QUERY: +QUERY,
      SELECTED_OBJECT_ID: +SELECTED_OBJECT_ID,
    };
  }

  public tryTmbbNavigation(
    params: { QUERY: number; SELECTED_OBJECT_ID: number; OBJECT_CONTENT_ID?: number },
    options?: { force: boolean }
  ): void {
    this.window.history.pushState(null, '', `/events?${param(params)}`);

    if (options && options.force) {
      this.window.dispatchEvent(new Event('popstate'));
    }
  }

  public initCollection(collection: any[], filter: ATTACHMENT_FILTER): void {
    this._filter = filter || {};
    this._collection$.next(collection);
  }

  public getCollection() {
    return this._collection$;
  }

  public filterAttachments(attachments: Attachment[]): Attachment[] {
    const filterKeys = Object.keys(this._filter);
    if (!filterKeys.length) {
      return attachments;
    }
    return attachments.filter((attachment: Attachment) => {
      return filterKeys.some((key: 'PROTO_ID' | 'PROTO_SIMHASH') => {
        return this._filter[key].includes(attachment[key]);
      });
    });
  }

  public isSelected(attachment: Attachment): boolean {
    const { OBJECT_CONTENT_ID } = this.getIdsFromPageUrl();
    return attachment.OBJECT_CONTENT_ID === OBJECT_CONTENT_ID;
  }

  public select(attachment: Attachment) {
    const { OBJECT_CONTENT_ID, OBJECT_ID } = attachment;
    const currentParams = this.getIdsFromPageUrl();

    const params = {
      QUERY: +currentParams.QUERY,
      SELECTED_OBJECT_ID: OBJECT_ID,
      OBJECT_CONTENT_ID: OBJECT_CONTENT_ID,
    };

    this.tryTmbbNavigation(params, { force: true });
  }

  public navigateToPreviousAttachment(events: { OBJECT_ID: number; objectContentFilenames: Attachment[] }[]) {
    const { QUERY, SELECTED_OBJECT_ID, OBJECT_CONTENT_ID } = this.getIdsFromPageUrl();

    const eventIndex = events.findIndex((event) => event.OBJECT_ID === SELECTED_OBJECT_ID);

    const attachments = this.filterAttachments(events[eventIndex].objectContentFilenames);

    const attachmentIndex = attachments.findIndex(
      (attachment: Attachment) => attachment.OBJECT_CONTENT_ID === OBJECT_CONTENT_ID
    );

    if (attachmentIndex === 0) {
      if (eventIndex === 0) {
        return;
      }

      const newEvent = events[eventIndex - 1];
      const newAttachments = this.filterAttachments(newEvent.objectContentFilenames);
      const newAttachmentIndex = newAttachments.length - 1;

      const params = {
        QUERY,
        SELECTED_OBJECT_ID: newEvent.OBJECT_ID,
        OBJECT_CONTENT_ID: newAttachments[newAttachmentIndex].OBJECT_CONTENT_ID,
      };
      this.tryTmbbNavigation(params, { force: true });
      return;
    }

    const newAttachment = attachments[attachmentIndex - 1];
    const params = {
      QUERY,
      SELECTED_OBJECT_ID,
      OBJECT_CONTENT_ID: newAttachment.OBJECT_CONTENT_ID,
    };
    this.tryTmbbNavigation(params, { force: true });
  }

  public navigateToNextAttachment(events: { OBJECT_ID: number; objectContentFilenames: Attachment[] }[]) {
    const { QUERY, SELECTED_OBJECT_ID, OBJECT_CONTENT_ID } = this.getIdsFromPageUrl();

    const eventIndex = events.findIndex((event) => event.OBJECT_ID === SELECTED_OBJECT_ID);

    const attachments = this.filterAttachments(events[eventIndex].objectContentFilenames);

    const attachmentIndex = attachments.findIndex(
      (attachment: Attachment) => attachment.OBJECT_CONTENT_ID === OBJECT_CONTENT_ID
    );

    if (attachmentIndex === attachments.length - 1) {
      if (eventIndex === events.length - 1) {
        return;
      }

      const newEvent = events[eventIndex + 1];
      const newAttachments = this.filterAttachments(newEvent.objectContentFilenames);
      const params = {
        QUERY,
        SELECTED_OBJECT_ID: newEvent.OBJECT_ID,
        OBJECT_CONTENT_ID: newAttachments[0].OBJECT_CONTENT_ID,
      };
      this.tryTmbbNavigation(params, { force: true });
      return;
    }

    const newAttachment = attachments[attachmentIndex + 1];
    const params = {
      QUERY,
      SELECTED_OBJECT_ID,
      OBJECT_CONTENT_ID: newAttachment.OBJECT_CONTENT_ID,
    };
    this.tryTmbbNavigation(params, { force: true });
  }
}
