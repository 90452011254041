import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Perimeter } from '../../typings/generated/perimeter';

@Injectable({
  providedIn: 'root',
})
export class TmPerimetersGroupService extends TmCollectionLoader<Perimeter> {
  public readonly src = '/api/perimeter';
  public idAttribute = 'PERIMETER_ID';
}
