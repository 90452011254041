import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerWebComponent } from '../custom-elements';
import { TmTumblerCheckboxComponent } from './tumbler-checkbox.component';
import { IwSharedModule } from '@platform/shared';
import { TmFormElementsModule } from '../form-elements/form-elements.module';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, IwSharedModule, TmFormElementsModule],
  declarations: [TmTumblerCheckboxComponent],
  providers: [],
  exports: [TmTumblerCheckboxComponent],
})
export class TmTumblerCheckboxModule {
  constructor() {
    registerWebComponent('tme-tumbler-checkbox', TmTumblerCheckboxComponent);
  }
}
