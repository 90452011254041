import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'tm-document-tab',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTabComponent extends TableTabComponent {
  public static scope = 'document' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.document';

  public scopeToEmitOnChange = DocumentTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    'with[document][entries_pool]': 'content',
    scopes: DocumentTabComponent.scope,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public columnDefs: ColDef[] = [
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      sortable: true,
      sort: 'asc',
      resizable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.fieldNames.displayName'),
    },
    {
      valueGetter: (params: ValueGetterParams) =>
        (params.data.entries_pool as any[])
          ?.reduce((accum: any[], entry) => {
            if (entry.content && entry.content.DISPLAY_NAME) {
              accum.push(entry.content.DISPLAY_NAME);
            }
            return accum;
          }, [])
          .join(', ') || '',
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.searchTitles.protectedDocument.technologies'),
    },
    {
      field: 'CREATE_DATE',
      sortable: true,
      resizable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.searchTitles.create_date'),
    },
    {
      field: 'CHANGE_DATE',
      sortable: true,
      resizable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.searchTitles.change_date'),
    },
    {
      field: 'NOTE',
      sortable: true,
      resizable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.searchTitles.note'),
    },
  ];
}
