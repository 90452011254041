import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { TmGridComponent } from '../../grid';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { sqlStringToDateTime } from '../../helpers/date';
import { TmBookwormFormatsService } from '../../bookworm';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IwBytesWithUnitPipe } from '@platform/shared';
import { ColDef } from 'ag-grid-community';

@UntilDestroy()
@Component({
  selector: 'tm-fingerprint-tab',
  templateUrl: './fingerprint-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FingerprintTabComponent extends TableTabComponent implements AfterViewInit {
  public static scope = 'fingerprint';
  public static i18nKey = '@tm-shared.search-select.searchTitles.fingerprint';

  public scopeToEmitOnChange = FingerprintTabComponent.scope;
  @ViewChild('grid') private tmGrid: TmGridComponent<any>;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: FingerprintTabComponent.scope,
  };

  public loading = true;
  protected formats?: { [key: string]: string };

  constructor(
    public service: TmSearchService,
    private _t: TranslateService,
    private formatsService: TmBookwormFormatsService,
    private _bytesWithUnitPipe: IwBytesWithUnitPipe,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  public columnDefs: ColDef[] = [
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      sort: 'asc',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
    },
    {
      field: 'MIME',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.fingerprint.mime'),
      valueFormatter: (params) => {
        return params.value && this.formats ? this.formats[params.value] : '';
      },
    },
    {
      field: 'FILE_PATH',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.fingerprint.file_path'),
    },
    {
      field: 'FILE_SIZE',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.fingerprint.file_size'),
      valueFormatter: (params) => (params.value ? this._bytesWithUnitPipe.transform(params.value) : ''),
    },
    {
      field: 'CREATE_DATE',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.fingerprint.createDate'),
      valueFormatter: (params) => {
        return params.value ? sqlStringToDateTime(params.value).toFormat('f') : '';
      },
    },
    {
      field: 'NOTE',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
    },
  ];

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this._fetchFormatsAndRefreshTable();
  }

  private _fetchFormatsAndRefreshTable() {
    this.formatsService.sharedData.pipe(untilDestroyed(this)).subscribe((f) => {
      f.data.forEach((item) => {
        if (!this.formats) {
          this.formats = {};
        }
        this.formats[item.mime_type] = item.name;
      });
      this.loading = false;
      this.tmGrid.refresh();
      this.cd.detectChanges();
    });
  }
}
