import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable({
  providedIn: 'root',
})
export class TmProtectedCatalogApiService extends TmCollectionLoader<TmApi.protectedCatalog.CollectionItem> {
  public readonly src = '/api/protectedCatalog';
  public idAttribute = 'CATALOG_ID';
}
