import { IconName } from '../../typings/generated/icons';
export const IconMnemoMap = new Map<string, IconName>(
  Object.entries({
    keylogger: 'keylogger',
    blocade: 'blocade',
    clipboard: 'clipboard',
    cloud_storage: 'cloud',
    crawler: 'crawler',
    email: 'mailclient',
    email_web: 'mailbrowser',
    file_read: 'blocade',
    file_copy_out: 'ftp',
    file_exchange: 'ftp',
    file_write: 'blocade',
    im_facebook: 'fb',
    im_mail_ru: 'message',
    im_skype: 'message',
    im_telegram: 'telegram',
    im_vk: 'vk',
    im_whatsapp: 'whatsapp',
    im_xmpp: 'message',
    network_resource: 'ftp',
    screenshot: 'screenshot',
    terminal_session: 'ftp',
    web_common: 'mailbrowser',
    whatsapp: 'whatsapp',
  })
);
