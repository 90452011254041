import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { registerWebComponent } from '../custom-elements';
import { TmeCheckboxComponent } from './checkbox.component';
import { IwCheckboxModule } from '@platform/shared';

@NgModule({
  imports: [CommonModule, IwCheckboxModule],
  declarations: [TmeCheckboxComponent],
  exports: [TmeCheckboxComponent],
})
export class TmeCheckboxModule {
  constructor() {
    registerWebComponent('tme-checkbox', TmeCheckboxComponent);
  }
}
