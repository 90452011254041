import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tm-tumbler-checkbox',
  templateUrl: './tumbler-checkbox.component.html',
})
export class TmTumblerCheckboxComponent {
  @Input() public checked: boolean;

  @Input() public disabled = false;
  @Output() public changed: EventEmitter<Event> = new EventEmitter();

  public checkboxSelectionChange(event: Event) {
    this.changed.emit(event);
  }
}
