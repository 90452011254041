import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AdditionalItem } from '../tab-components/common';
import { getSearchSelectModelFromEventModel } from '../../api-services/functions';
import { EventsSelectModel } from '../../../plugins/events/events-select.model';

@Component({
  template: '',
})
export abstract class TmeAbstractQueryComponent {
  @Input() public name = '';
  @Input() public mode: 'lite' | 'advanced' = 'lite';
  @Input() public set modaltitle(title: string) {
    if (title) {
      this._title = title;
    }
  }
  public get modaltitle() {
    return this._title;
  }
  @Input()
  public formControl = new FormControl();
  public loading = false;
  public additionalItems: Observable<AdditionalItem[]> = of([]);

  private _title: string;

  @Input() public set value(val: any) {
    this.formControl.patchValue(
      val.map((item: any) => {
        return getSearchSelectModelFromEventModel(item);
      })
    );
  }

  public get value() {
    return this.formControl.value?.map((item: EventsSelectModel) => item.data.mapped) || [];
  }
}
