import { ConfigDataGet, SandboxStatus, Status, ProcessingStatus, LastOperation } from './generated/config';
import { TranslateService } from '@ngx-translate/core';

export function getFlagsByConfig(
  config: ConfigDataGet,
  t: TranslateService,
  canRevert?: boolean,
  isCurrentUser?: boolean,
  hasUser?: boolean
) {
  const showButtons =
    (config.STATUS !== Status.FREE && Boolean(isCurrentUser)) ||
    config.STATUS === Status.SAVED ||
    config.SANDBOX_STATUS === SandboxStatus.SAVED;

  const showRevertButton =
    (config.STATUS !== Status.FREE && (Boolean(isCurrentUser) || Boolean(canRevert))) ||
    config.STATUS === Status.SAVED ||
    config.SANDBOX_STATUS === SandboxStatus.SAVED;

  const showLastEdited =
    config.PROCESSING_STATUS === ProcessingStatus.APPLIED ||
    config.STATUS === Status.SAVED ||
    config.SANDBOX_STATUS === SandboxStatus.SAVED;

  const inProgress = config.STATUS === Status.IS_TRAINING || config.PROCESSING_STATUS === ProcessingStatus.COMMITING;

  const isRollbacking =
    config.PROCESSING_STATUS === ProcessingStatus.COMMITING &&
    config.SANDBOX_STATUS !== SandboxStatus.SAVED &&
    config.LAST_OPERATION !== null &&
    config.LAST_OPERATION !== LastOperation.ROLLBACK;

  const showUsername =
    !isCurrentUser &&
    (config.SANDBOX_STATUS === SandboxStatus.SAVED ||
      config.STATUS === Status.SAVED ||
      config.SANDBOX_STATUS === SandboxStatus.LOCKED ||
      config.STATUS === Status.LOCKED) &&
    Boolean(hasUser);

  const flags = {
    disableApplyReason: '',
    disableRevertReason: '',
    hideSaveButton: config.STATUS === Status.SAVED || config.SANDBOX_STATUS === SandboxStatus.SAVED,
    disableApply: config.STATUS === Status.NEEDS_TRAINING || isRollbacking,
    disableRevert: config.STATUS === Status.IS_TRAINING || isRollbacking,
    showButtons,
    inProgress,
    showUsername,
    showLastEdited,
    showRevertButton,
  };

  if (flags.disableApply) {
    // @translate config.widget.title.image_classifier
    // @translate config.widget.title.autoling
    const tech = t.instant(`config.widget.title.${config.TECHNOLOGY}`);
    flags.disableApplyReason = t.instant('config.widget.title.needsTraining', { tech });
    return flags;
  }

  flags.disableApply = flags.disableRevert;
  if (flags.disableRevert) {
    const tech = t.instant(`config.widget.title.${config.TECHNOLOGY}`);
    flags.disableApplyReason = t.instant('config.widget.title.is_training', { tech });
    flags.disableRevertReason = t.instant('config.widget.title.is_training', { tech });
  }
  return flags;
}
