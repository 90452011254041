import { ExistingProvider, NgZone } from '@angular/core';
import { TmbbTechLoaderService } from './tech-loader/tech-loader-tmbb.service';
import { TmPolicyService } from './policy/policy.service';
import { TmBrowserTabSyncService } from './browser-tab-sync/browser-tab-sync.service';
import { TmFormElementService } from './form-elements/form-elements.service';
import { TmHelpersService } from './helpers/helpers.service';
import { TmCommonErrorsAjaxInterceptor } from '../plugins/tm/tm-errors-ajax.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { IwIconService, IwNotificationsService } from '@platform/shared';
import { TmExportMonitorService } from '../plugins/events/components/export-monitor/export-monitor.service';
import { TmIdlerService } from './idler';
import { TmEventsService } from '../plugins/events/events.service';
import { TmDownloaderService } from './downloader/downloader.service';
import { TmEventsSearchWidgetService } from '../plugins/events/events-search-widget/events-search-widget.service';
import { TmEventMigrationService } from './legacy-container/events-migration.service';
import { TmPluginService } from './plugin/plugin.service';
import { TmSessionService } from './session';
import { TmEventsQueryService } from '../plugins/events/query/events-query.service';

const PROVIDERS = {
  TmbbTechLoaderService,
  TmFormElementService,
  TmBrowserTabSyncService,
  TmPolicyService,
  NgZone,
  TranslateService,
  TmHelpersService,
  TmCommonErrorsAjaxInterceptor,
  TmExportMonitorService,
  IwNotificationsService,
  TmIdlerService,
  TmEventsService,
  TmDownloaderService,
  TmEventsSearchWidgetService,
  TmEventMigrationService,
  TmPluginService,
  TmSessionService,
  TmEventsQueryService,
  IwIconService,
};

// текстовые названия провайдеров для вызова в кофе
export const NAMED_PROVIDERS: ExistingProvider[] = Object.keys(PROVIDERS).map((key: string) => ({
  provide: key,
  // @ts-ignore
  useExisting: PROVIDERS[key],
}));
