<div class="header">
  <h2 class="title">{{ '@tm-shared.techLoader.title' | translate }}</h2>
  <button iw-button class="iconButton" type="button">
    <tm-icon
      [style.transform]="collapsed ? '' : 'rotate(180deg)'"
      svgIcon="triangle"
      (click)="toggleCollapse()"
    ></tm-icon>
  </button>
  <button iw-button class="iconButton" type="button">
    <tm-icon svgIcon="delete" (click)="close()"></tm-icon>
  </button>
</div>
<div class="groupList" *ngIf="!collapsed">
  <div class="group" *ngFor="let group of groupedItems$ | async; trackBy: trackByFnGroup">
    <h3 class="groupName">{{ group.i18nKey | translate }}</h3>
    <div class="itemRow" *ngFor="let item of group.items; trackBy: trackByFnItem">
      <div class="name" [iwPopover]="titlePopover" [context]="{ text: item.title }" [showDelay]="500">
        {{ item.title }}
      </div>
      <ng-container [ngSwitch]="item.status">
        <div *ngSwitchCase="itemStatus.success" class="status success">
          <tm-icon svgIcon="success"></tm-icon>
          {{ item.statusText || ('@tm-shared.techLoader.status.success' | translate) }}
        </div>
        <div *ngSwitchCase="itemStatus.downloadable" class="status downloadable">
          <a [href]="item.url" target="_blank">
            {{ item.statusText || ('@tm-shared.techLoader.status.success' | translate) }}
          </a>
        </div>
        <div *ngSwitchCase="itemStatus.error" class="status error">
          <div *ngIf="item.errors && item.errors.length > 0; then fileErrors; else otherErros"></div>
          <ng-template #fileErrors>
            <div (click)="downloadErrors(item.errors!)" class="download-link">
              <tm-icon svgIcon="successWarning"></tm-icon>
              <span>{{ '@tm-shared.techLoader.importedWithErrors' | translate }} ({{ item.errors?.length }})</span>
              <tm-icon svgIcon="download"></tm-icon>
            </div>
          </ng-template>
          <ng-template #otherErros>
            <tm-icon svgIcon="forbidden" class="loader-icon"></tm-icon>
            <ng-container #otherErros *ngIf="item.errorPopover as errorMsg; else simpleError">
              <span
                [iwPopover]="item.errorPopoverUseHtml ? errorPopoverHtml : errorPopover"
                [context]="{ errorMsg: errorMsg }"
                >{{ item.statusText || ('@tm-shared.techLoader.status.error' | translate) }}</span
              >
            </ng-container>
            <ng-template #simpleError>
              <span>{{ item.statusText || ('@tm-shared.techLoader.status.error' | translate) }}</span>
            </ng-template>
          </ng-template>
        </div>
        <div *ngSwitchCase="itemStatus.inProgress" class="status progress">
          <tm-spinner size="15px" class="loadingSpinner"></tm-spinner>
          {{ item.statusText || ('@tm-shared.techLoader.status.inProgress' | translate) }}
        </div>
        <div *ngSwitchCase="itemStatus.warning" class="status warning">
          <tm-icon svgIcon="help" class="loader-icon"></tm-icon>
          <ng-container *ngIf="item.errorPopover as errorMsg; else simpleError">
            <span
              [iwPopover]="item.errorPopoverUseHtml ? errorPopoverHtml : errorPopover"
              [context]="{ errorMsg: errorMsg }"
              >{{ item.statusText || ('@tm-shared.techLoader.status.warning' | translate) }}</span
            >
          </ng-container>
          <ng-template #simpleError>
            <span>{{ item.statusText || ('@tm-shared.techLoader.status.warning' | translate) }}</span>
          </ng-template>
        </div>
      </ng-container>
      <div class="" *ngIf="item.statusDescription">{{ item.statusDescription }}</div>
    </div>
  </div>
</div>

<tm-dropzone
  #importFiles
  class="hidden"
  accept=".xml,.cfb,.zip"
  (change)="importFilesInputChanged($event)"
></tm-dropzone>

<ng-template #errorPopover let-errorMsg="errorMsg">
  <div class="errorPopover">{{ errorMsg }}</div>
</ng-template>

<ng-template #errorPopoverHtml let-errorMsg="errorMsg">
  <div class="errorPopover" [innerHTML]="errorMsg"></div>
</ng-template>

<ng-template #titlePopover let-text="text">
  <span class="titleInPopover">{{ text }}</span>
</ng-template>
