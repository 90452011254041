import { Injectable } from '@angular/core';
import { TmSearchService } from '../api-services';
import { Searchable } from './tab-components/common';
import { map } from 'rxjs/operators';
import { getValueByScope } from '../api-services/functions';
import { EventsSelectModel } from '../../plugins/events/events-select.model';

@Injectable()
export class SearchableSearchService implements Searchable {
  constructor(private service: TmSearchService) {}

  public search(params: { query: string; [p: string]: string }) {
    return this.service.get({ params }).pipe(
      map((response) => response.data),
      map((scopes) => this.mapScopesToCondition(scopes))
    );
  }

  private mapScopesToCondition(scopes: TmApi.search.CollectionItem): EventsSelectModel[] {
    return Object.keys(scopes).reduce((accum: EventsSelectModel[], scope: TmApi.search.Scopes) => {
      const mappedValues = (scopes[scope] as any[]).map((item) => getValueByScope(item, scope));
      if (mappedValues.length) {
        accum.push(...mappedValues);
      }
      return accum;
    }, []);
  }
}
