import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { sqlStringToDateTime } from '../../helpers/date';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'tm-text-object-tab',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextObjectTabComponent extends TableTabComponent {
  public static scope = 'text_object' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.textObject';

  public scopeToEmitOnChange = TextObjectTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: TextObjectTabComponent.scope,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public columnDefs = <ColDef[]>[
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      sort: 'asc',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
    },
    {
      field: 'CREATE_DATE',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.textObject.createDate'),
      valueFormatter: (params) => {
        return params.value ? sqlStringToDateTime(params.value).toFormat('f') : '';
      },
    },
    {
      field: 'COUNTRY',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.textObject.country'),
      valueFormatter: (params) => {
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.all
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.are
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.bhr
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.blr
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.kaz
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.kir
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.mys
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.rus
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.sau
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.uzb
        // @translate @tm-shared.search-select.headerNames.textObject.countryName.vnm
        return params.value
          ? this._t.instant(`@tm-shared.search-select.headerNames.textObject.countryName.${params.value}`)
          : '';
      },
    },
    {
      field: 'NOTE',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
    },
  ];
}
