import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  templateUrl: 'date-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellComponent implements AgRendererComponent {
  public date: string;
  public dateOutputFormat: string;

  public agInit(data: any): void {
    this.dateOutputFormat = data.dateOutputFormat || 'f';
    this._updateDate(data.value);
  }

  public refresh(data: any): boolean {
    this._updateDate(data.value);
    return true;
  }

  private _updateDate(data: string) {
    this.date = data;
  }
}
