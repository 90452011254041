import { Component } from '@angular/core';
import { SearchParams } from '../tab-components/common';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { TagTabComponent } from '../tab-components/tag-tab.component';

@Component({
  templateUrl: 'wrapper.html',
  styleUrls: ['wrapper-style.scss'],
})
export class TmeTagsComponent extends TmeAbstractQueryComponent {
  public components = [TagTabComponent];
  public params: SearchParams = {
    queryParams: {
      type: 'query',
      scopes: 'tag',
    },
  };
}
