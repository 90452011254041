import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IwPopoverOptions } from '@platform/shared';
import { TmDownloaderService } from '@tm-shared/downloader/downloader.service';
import { TmDropzoneComponent, DropzoneOutValue } from '@tm-shared/dragndrop/dropzone.component';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { TmTechLoaderComponentService } from './tech-loader-component.service';
import { TmTechLoaderGroup, TmTechLoaderItem, TmTechLoaderStatus, TmTechLoaderType } from './tech-loader.model';

@Component({
  selector: 'tm-tech-loader',
  templateUrl: 'tech-loader.component.html',
  styleUrls: ['tech-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
        noStyles: true,
        placement: 'top',
      }),
    },
  ],
})
export class TmTechLoaderComponent implements OnDestroy, OnInit {
  public groupedItems$: Observable<TmTechLoaderGroup[]> = this._service.groupedItems$;

  public collapsed = false;

  public itemStatus = TmTechLoaderStatus;

  @HostBinding('class.empty') public empty = false;

  @ViewChild('importFiles', { static: true }) private _importDropzoneEl: TmDropzoneComponent;

  private _destroy$ = new Subject<void>();

  private techLoaderImport: TmTechLoaderType;

  constructor(
    private _service: TmTechLoaderComponentService,
    private _cd: ChangeDetectorRef,
    private _downloader: TmDownloaderService
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this._service.groupedItems$
      .pipe(
        tap((groups) => (this.empty = !groups.length)),
        delay(0),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this._cd.detectChanges());

    this._service.showImport$.pipe(takeUntil(this._destroy$)).subscribe((type) => {
      this.techLoaderImport = type;
      this._importDropzoneEl.showFileSelector();
    });
  }

  public toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  public close(): void {
    this._service.clearItems();
  }

  public importFilesInputChanged(value: DropzoneOutValue): void {
    const files = value ? (Array.isArray(value) ? value : [value]) : [];
    this._service.importFiles(this.techLoaderImport, files);
    this._importDropzoneEl.clear();
  }

  public trackByFnItem(_index: number, obj: TmTechLoaderItem) {
    return obj.key;
  }

  public trackByFnGroup(_index: number, obj: TmTechLoaderGroup) {
    return obj.key;
  }

  public downloadErrors(errors: object[]) {
    const textErrors = [];
    textErrors.push(JSON.stringify(errors, null, 4));
    const file = new File(textErrors, 'iwtm-groupImport-errors-' + Date.now(), { type: 'text/plain' });
    this._downloader.download(file, 'iwtm-groupImport-errors-' + Date.now());
  }
}
