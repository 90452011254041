import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Blowfish } from 'javascript-blowfish';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, combineLatest, empty, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import ApiWs = require('typings/generated/websocket');
import { cookies } from '../helpers';
import { SaltResponse, SessionModel, SessionResponse } from './tm-session.model';

export enum LoginState {
  unknown,
  loggedOut,
  loggedIn,
}

export const langs = {
  en: 'eng',
  ru: 'rus',
};

const SERVER_API = {
  logout: '/api/logout',
  login: '/api/login',
  user: '/api/user',
  salt: '/api/salt',
};

@Injectable({
  providedIn: 'root',
})
export class TmSessionService {
  public loginState$: Observable<LoginState> = of(null).pipe(
    switchMap(() => this._loginState$),
    distinctUntilChanged()
  );

  /**
   * isLoading$ stream
   */
  public isLoading$: Observable<boolean> = this.loginState$.pipe(map((state) => state === LoginState.unknown));

  /**
   * login state stream
   */
  public isLoggedIn$: Observable<boolean> = this.loginState$.pipe(map((state) => state === LoginState.loggedIn));
  public session$ = of(null).pipe(
    switchMap(() =>
      combineLatest(this._session$, this.isLoggedIn$).pipe(
        filter(([_session, loggedIn]) => !!loggedIn && !!_session),
        map(([session]) => session)
      )
    )
  ) as Observable<SessionModel>;

  public userId$: Observable<number | null> = of(null).pipe(
    switchMap(() => this.loginState$),
    switchMap((state) => {
      switch (state) {
        case LoginState.unknown:
          return empty();
        case LoginState.loggedOut:
          return of(null);
        case LoginState.loggedIn:
          return this.session$.pipe(map((session) => session.USER_ID));
      }
    })
  );

  public redirectTo?: string;
  /**
   * Login state stream
   */
  private _loginState$: BehaviorSubject<LoginState> = new BehaviorSubject(LoginState.unknown);

  /**
   * Session data stream
   */
  private _session$: BehaviorSubject<SessionModel | null> = new BehaviorSubject(null);

  constructor(private _router: Router, private _http: HttpClient) {
    this.loginState$.subscribe((state) => {
      switch (state) {
        case LoginState.loggedIn:
          return this._onLogin();
        case LoginState.loggedOut:
          return this._onLogout();
        default:
          break;
      }
    });
  }

  public getCurrentSession(): SessionModel | null {
    return this._session$.getValue();
  }

  public isCurrentUserById(id: number): Observable<boolean> {
    return this.session$.pipe(map((data) => (data ? data.USER_ID === id : false)));
  }

  /**
   * Login with provided credentials
   */
  public login(credentials: { login: string; password: string }): Observable<boolean> {
    return this._encryptPassword(credentials.password).pipe(
      switchMap((encryptedPassword) => {
        const data = JSON.stringify({
          username: credentials.login,
          crypted_password: encryptedPassword,
        });
        const headers = new HttpHeaders({
          'X-Timezone': DateTime.local().toFormat('ZZ'),
        });

        return this._http.post(SERVER_API.login, data, { headers });
      }),
      map((response: SessionResponse) => {
        this._session$.next(response.data);
        this._loginState$.next(LoginState.loggedIn);
        return true;
      })
    );
  }

  // backbone integration
  public setLoginResponse(response: SessionResponse) {
    this._session$.next(response.data);
    this._loginState$.next(LoginState.loggedIn);
  }

  /**
   * Logout
   */
  public logout(): Observable<boolean> {
    return this._http.get(SERVER_API.logout).pipe(
      catchError((e) => {
        // Handle when already logged out
        if (e instanceof HttpErrorResponse && e.status === 403) {
          return of(null);
        }

        return throwError(e);
      }),
      map(() => {
        cookies.delete();
        this._session$.next(null);
        this._loginState$.next(LoginState.loggedOut);
        return true;
      })
    );
  }

  /**
   * Try to restore current session and set login state
   */
  public restoreSession(): void {
    this._http.get(SERVER_API.user + '/check').subscribe({
      next: (response: TmApi.GetResponse<SessionModel>) => {
        this._session$.next(response.data);
        this._loginState$.next(LoginState.loggedIn);
      },
      error: () => {
        this._session$.next(null);
        this._loginState$.next(LoginState.loggedOut);
      },
    });
  }

  /**
   * Change UI language
   * @param language language key
   */
  public changeLanguage(language: keyof typeof langs) {
    return this.session$.pipe(
      tap((session: SessionModel) => (session.LANGUAGE = this._mapLangToLangOnServer(language))),
      take(1),
      switchMap((session: SessionModel) =>
        this._http.put(SERVER_API.user + '/' + session.USER_ID, session).pipe(
          tap(() => {
            this._session$.next(session);
          })
        )
      )
    );
  }

  public updateBySocketMessage(message: ApiWs.WsMessage): void {
    if (message.error !== 'user_deleted' && message.error !== 'user_disabled') {
      return;
    }

    this.logout().subscribe();
  }

  private _encryptPassword(password: string): Observable<string> {
    return this._http.get<SaltResponse>(SERVER_API.salt).pipe(
      map((response) => {
        const crypter = new Blowfish(response.data.salt);
        const encrypted = crypter.encrypt(password);
        return crypter.base64Encode(encrypted);
      })
    );
  }

  /**
   * en -> eng
   * ru -> rus
   * Mapping for linux & db
   */
  private _mapLangToLangOnServer(language: keyof typeof langs) {
    return langs[language];
  }

  /**
   * Tasks on user is logged in
   */
  private _onLogin(): void {
    if (this.redirectTo) {
      this._router.navigateByUrl(this.redirectTo).then((success) => {
        if (!success) {
          location.assign('/');
        }
      });
      delete this.redirectTo;
    }
  }

  /**
   * Tasks on user is logged out
   */
  private _onLogout() {}
}
