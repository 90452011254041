import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TmBookwormProtocolsService extends TmStatefulService<TmApi.GetResponse<TmApi.Protocol.CollectionItem[]>> {
  public src = '/api/bookworm/protocols';

  public getNameById(key: string): Observable<string> {
    return this.sharedData.pipe(map((response) => response.data.find((item) => item.protocol_id === key)?.name || key));
  }
  public getNameByMnemo(mnemo: string): Observable<string> {
    return this.sharedData.pipe(map((response) => response.data.find((item) => item.mnemo === mnemo)?.name || mnemo));
  }
}
