import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmConfigWidgetComponent } from './config-widget/config-widget.component';
import { TmConfigWidgetService } from './config-widget/config-widget.service';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';
import { TmSpinnerModule } from '../../@tm-shared/spinner';
import { TmPrivilegesModule } from '../../@tm-shared/privileges/privileges.module';
import { registerWebComponent } from '../../@tm-shared/custom-elements';
import { TmAutolingTrainingService } from 'plugins/tech-autoling/autoling-training.service';
import { TmGraphicTrainingService } from 'plugins/tech-graphic/graphic-training.service';

import ru from './i18n/config.ru.json';

import en from './i18n/config.en.json';

@NgModule({
  imports: [CommonModule, IwSharedModule, TmIconModule, TranslateModule, TmSpinnerModule, TmPrivilegesModule],
  exports: [TmConfigWidgetComponent],
  declarations: [TmConfigWidgetComponent],
  providers: [TmConfigWidgetService, TmAutolingTrainingService, TmGraphicTrainingService],
})
export class TmConfigElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
    registerWebComponent('tme-config-widget', TmConfigWidgetComponent);
  }
}
