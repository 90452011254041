<div *ngIf="showOnPage" class="{{ options.type | async }}" class="notificationContainer">
  <!--
        @translate settings-license.notification.unlicensed_period_with_reserve
        @translate settings-license.notification.license_expired
        @translate settings-license.notification.no_license_message_with_reserve
        @translate settings-license.notification.no_license_message
      -->
  <div class="notificationMessage">{{ options.text | async }}</div>
  <div *ngIf="(options.type | async) === TYPES.warn" class="notificationClose">
    <tm-icon [size]="'25'" (click)="showOnPage = false" svgIcon="close"></tm-icon>
  </div>
</div>
