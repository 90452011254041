import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmChannelService } from '@tm-shared/channel';
import { objectToFormData } from '@tm-shared/helpers/form';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WsMessage } from 'typings/generated/websocket';
import {
  TmTechLoaderAction,
  TmTechLoaderGroup,
  TmTechLoaderItem,
  TmTechLoaderStatus,
  TmTechLoaderType,
} from './tech-loader.model';

@Injectable({
  providedIn: 'root',
})
export class TmTechLoaderComponentService {
  public groupedItems$: Observable<TmTechLoaderGroup[]>;

  public showImport$ = new EventEmitter<TmTechLoaderType>();

  private _groups$ = new BehaviorSubject<TmTechLoaderGroup[]>([]);

  constructor(private _t: TranslateService, private _http: HttpClient, private _channel: TmChannelService) {
    this.groupedItems$ = this._groups$.pipe(map((groups) => groups.filter((g) => g.items.length > 0)));

    this.initGroup({
      key: TmTechLoaderAction.export,
      // @translate @tm-shared.techLoader.exportGroupTitle
      i18nKey: '@tm-shared.techLoader.exportGroupTitle',
      items: [],
    });
    this.initGroup({
      key: TmTechLoaderAction.import,
      // @translate @tm-shared.techLoader.importGroupTitle
      i18nKey: '@tm-shared.techLoader.importGroupTitle',
      items: [],
    });

    this._channel.getUserChannel('analysis').subscribe((msg) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.technology, msg);
      }
    });

    this._channel.getUserChannel('protected').subscribe((msg) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.protected, msg);
      }
    });

    this._channel.getUserChannel('policy').subscribe((msg) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.policy, msg);
      }
    });

    this._channel.getUserChannel('perimeter').subscribe((msg) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.perimeter, msg);
      }
    });

    const groupHandler = (msg: WsMessage) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.group, msg);
      }
    };

    this._channel.getUserChannel('ldap_group').subscribe(groupHandler);
    this._channel.getUserChannel('group').subscribe(groupHandler);

    this._channel.getUserChannel('list').subscribe((msg) => {
      if ([TmTechLoaderAction.export, TmTechLoaderAction.import].includes(msg.meta.method)) {
        this.updateImportOrExport(msg.meta.method, TmTechLoaderType.resources, msg);
      }
    });
  }

  public updateImportOrExport(key: TmTechLoaderAction, type: TmTechLoaderType, msg: WsMessage): void {
    const itemKey = this._getProcessKey(type, key);
    let nextState: Partial<TmTechLoaderItem>;
    switch (msg.state) {
      case 'running':
        this.updateItemInGroup(key, {
          key: itemKey,
          status: TmTechLoaderStatus.inProgress,
          statusText: '',
          statusDescription: msg.meta.percent,
        });
        break;
      case 'success':
        this.updateItemInGroup(key, {
          key: itemKey,
          status: key === TmTechLoaderAction.import ? TmTechLoaderStatus.success : TmTechLoaderStatus.downloadable,
          statusText: key === TmTechLoaderAction.import ? '' : this._t.instant('@tm-shared.techLoader.downloadFile'),
          url: `${location.origin}${msg.meta.uri}`,
          statusDescription: '',
        });

        // Download file on success
        if (msg.meta.uri) {
          window.open(`${location.origin}${msg.meta.uri}`);
        }
        break;
      case 'error':
        nextState = {
          key: itemKey,
          status: TmTechLoaderStatus.error,
          statusText: '',
          statusDescription: '',
          errors: msg.meta.errors,
        };
        this._setErrorPopover(nextState, msg.error!);
        this.updateItemInGroup(key, nextState);
        break;
    }
  }

  /**
   * Add item to group
   */
  public addItemToGroup(groupKey: string, item: TmTechLoaderItem): void {
    this._addItems(groupKey, [item]);
  }

  /**
   * Add multiple items to group
   */
  public addItemsToGroup(groupKey: string, items: TmTechLoaderItem[]): void {
    this._addItems(groupKey, items);
  }

  public clearItems(): void {
    this._groups$.next(this._groups$.getValue().map((group) => Object.assign({}, group, { items: [] })));
  }

  /**
   * Initialize tech loader group
   */
  public initGroup(group: TmTechLoaderGroup): void {
    this._setGroup(group.key, group);
  }

  public removeItemFromGroup(groupKey: string, itemKey: string): void {
    this._removeItems(groupKey, [itemKey]);
  }

  public removeItemsFromGroup(groupKey: string, itemKey: string[]): void {
    this._removeItems(groupKey, itemKey);
  }

  public updateItemInGroup(groupKey: string, updatedItem: Partial<TmTechLoaderItem>): void {
    this._updateItems(groupKey, [updatedItem]);
  }

  public updateItemsInGroup(groupKey: string, updatedItems: Partial<TmTechLoaderItem>[]): void {
    this._updateItems(groupKey, updatedItems);
  }

  /**
   * This may be used to update temporary key (local ones) with the const value from sample compiler
   */
  public updateItemKey(groupKey: string, itemKey: string, newItemKey: string): void {
    const group = this._getGroup(groupKey);

    if (!group) {
      return;
    }

    let existsAt = group.items.findIndex((currentItem) => currentItem.key === itemKey);

    if (existsAt === -1) {
      return;
    }

    group.items[existsAt] = Object.assign({}, group.items[existsAt], {
      key: newItemKey,
    });
  }

  public import(type: TmTechLoaderType): void {
    this.showImport$.next(type);
  }

  public importFiles(type: TmTechLoaderType, files: File[]): void {
    const itemKey = this._getProcessKey(type, TmTechLoaderAction.import);
    let importUrl: string;
    let itemTitle: string;

    switch (type) {
      case TmTechLoaderType.policy:
        importUrl = '/api/policy/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importPolicy');
        break;
      case TmTechLoaderType.technology:
        importUrl = '/api/category/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importTechnology');
        break;
      case TmTechLoaderType.protected:
        importUrl = '/api/protectedCatalog/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importProtected');
        break;
      case TmTechLoaderType.perimeter:
        importUrl = '/api/perimeter/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importPerimeter');
        break;
      case TmTechLoaderType.group:
        importUrl = '/api/ldapGroup/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importGroup');
        break;
      case TmTechLoaderType.resources:
        importUrl = '/api/systemList/import';
        itemTitle = this._t.instant('@tm-shared.techLoader.importResources');
        break;
      default:
        throw new Error('bad import type');
    }

    this.addItemToGroup(TmTechLoaderAction.import, {
      key: itemKey,
      title: itemTitle,
      status: TmTechLoaderStatus.inProgress,
    });

    this._http
      .post(
        importUrl,
        objectToFormData({
          'files[]': files,
        })
      )
      .pipe(
        catchError((res) => {
          const nextState: Partial<TmTechLoaderItem> = {
            key: itemKey,
            status: TmTechLoaderStatus.error,
          };
          this._setErrorPopover(nextState, res.error.error);
          this.updateItemInGroup(TmTechLoaderAction.import, nextState);
          return of(null);
        })
      )
      .subscribe({
        error: () => {
          this.updateItemInGroup(TmTechLoaderAction.import, {
            key: itemKey,
            status: TmTechLoaderStatus.error,
            statusText: '',
            statusDescription: '',
          });
        },
      });
  }

  public export(type: TmTechLoaderType): void {
    const itemKey = this._getProcessKey(type, TmTechLoaderAction.export);
    let exportUrl: string;
    let itemTitle: string;

    switch (type) {
      case TmTechLoaderType.policy:
        exportUrl = '/api/policy/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportPolicy');
        break;
      case TmTechLoaderType.technology:
        exportUrl = '/api/category/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportTechnology');
        break;
      case TmTechLoaderType.protected:
        exportUrl = '/api/protectedCatalog/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportProtected');
        break;
      case TmTechLoaderType.perimeter:
        exportUrl = '/api/perimeter/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportPerimeter');
        break;
      case TmTechLoaderType.group:
        exportUrl = '/api/ldapGroup/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportGroup');
        break;
      case TmTechLoaderType.resources:
        exportUrl = '/api/systemList/export';
        itemTitle = this._t.instant('@tm-shared.techLoader.exportResources');
        break;
      default:
        throw new Error('bad export type');
    }

    this.addItemToGroup(TmTechLoaderAction.export, {
      key: itemKey,
      title: itemTitle,
      status: TmTechLoaderStatus.inProgress,
    });

    this._http.get(exportUrl).subscribe({
      error: () => {
        this.updateItemInGroup(TmTechLoaderAction.export, {
          key: itemKey,
          status: TmTechLoaderStatus.error,
          statusText: '',
          statusDescription: '',
        });
      },
    });
  }

  // @translate @tm-shared.techLoader.validationErrors.badExtension
  // @translate @tm-shared.techLoader.validationErrors.xmlFileNotFound
  // @translate @tm-shared.techLoader.validationErrors.undefined
  public getErrorCode(errorCode: string): string {
    if (errorCode === 'bad_extension') {
      return `@tm-shared.techLoader.validationErrors.badExtension`;
    }
    if (errorCode === 'xml_file_not_found') {
      return `@tm-shared.techLoader.validationErrors.xmlFileNotFound`;
    }
    return `@tm-shared.techLoader.validationErrors.undefined`;
  }

  private _getProcessKey(type: TmTechLoaderType, action: TmTechLoaderAction): string {
    return `${type}-${action}-process`;
  }

  private _getGroup(groupKey: string): TmTechLoaderGroup | null {
    return this._groups$.getValue().find((group) => group.key === groupKey) || null;
  }

  /**
   * Remove items
   */
  private _addItems(groupKey: string, items: TmTechLoaderItem[]): void {
    const group = this._getGroup(groupKey);

    if (!group) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      let existsAt = group.items.findIndex((currentItem) => currentItem.key === items[i].key);

      if (existsAt > -1) {
        Object.assign(group.items[existsAt], items[i]);
        continue;
      }

      group.items.push(items[i]);
    }

    this._patchGroup(groupKey, {
      items: group.items,
    });
  }

  /**
   * Remove items by keys
   */
  private _removeItems(groupKey: string, keys: string[]): void {
    const group = this._getGroup(groupKey);

    if (!group) {
      return;
    }

    this._patchGroup(groupKey, {
      items: group.items.filter((item) => !keys.includes(item.key)),
    });
  }

  /**
   * Update existed items
   */
  private _updateItems(groupKey: string, updatedItems: Partial<TmTechLoaderItem>[]): void {
    const group = this._getGroup(groupKey);

    if (!group) {
      return;
    }

    this._patchGroup(groupKey, {
      items: group.items.map((current) =>
        Object.assign(
          {},
          current,
          updatedItems.find((updated) => current.key === updated.key)
        )
      ),
    });
  }

  /**
   * Reset group data or create new group
   */
  private _setGroup(groupKey: string, groupData: TmTechLoaderGroup): void {
    const groups = Array.from(this._groups$.getValue());
    const groupIndex = groups.findIndex((group) => group.key === groupKey);

    if (groupIndex > -1) {
      groups.splice(groupIndex, 1, Object.assign({}, groups[groupIndex], groupData));
    } else {
      groups.push(groupData);
    }

    this._groups$.next(groups);
  }

  /**
   * Patch existing group
   */
  private _patchGroup(groupKey: string, update: Partial<TmTechLoaderGroup>): void {
    const groups = Array.from(this._groups$.getValue());
    const groupIndex = groups.findIndex((group) => group.key === groupKey);

    if (groupIndex === -1) {
      throw new Error(`No "${groupKey}" group found to patch`);
    }

    groups[groupIndex] = Object.assign({}, groups[groupIndex], update);
    this._groups$.next(groups);
  }

  private _setErrorPopover(nextState: Partial<TmTechLoaderItem>, errorCode: string) {
    nextState.errorPopover = this._t.instant(this.getErrorCode(errorCode));
  }
}
