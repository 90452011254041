import { TmTechKey } from '@tm-shared/license';
import { TmActionRequest } from '../../typings/generated/privileges';

export interface TmPrivilegeModel {
  ROLE_ID: number;
  PRIVILEGE_CODE: string;
}

export interface TmModuleRequest {
  module: string;
}

export type TmAccessKey = string;

export type TmAccessRequest = TmActionRequest | TmModuleRequest | TmAccessKey;

export type TmPrivilegeMapItem = {
  id: string;
  key: string;
  children: string[];
  parent?: string;
  value?: false | TmPrivilegeMapItemValue;
  requiredLicenses?: TmTechKey[];
};

export type TmPrivilegeMapItemValue = {
  selected?: boolean;
  depends?: string[];
  dependencies?: string[];
};

export function isTmActionRequest(request: TmAccessRequest): request is TmActionRequest {
  return (<TmActionRequest>request).type !== undefined;
}

export function isTmModuleRequest(request: TmAccessRequest): request is TmModuleRequest {
  return (<TmModuleRequest>request).module !== undefined;
}

export interface PrivilegeItem {
  [key: string]: boolean | string[] | PrivilegeItem;
}

export interface Privilege {
  [key: string]: {
    [key: string]: boolean | PrivilegeItem;
  };
}

export const privilegesDisabledByConfiguration = [
  /lists.*(edit|delete)/,
  'settings:notification:edit',
  /policy.*(edit_rule|edit_action)/,
  /policy.*(edit|delete)/,
  /protected:document:(edit|delete)/,
  /organization.*(edit|delete|set_status)/,
  /analysis.*(edit|delete|full_access)/,
];
