import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService, IwPopoverDirective } from '@platform/shared';
import { TmChannelService } from '@tm-shared/channel';
import { ExportFilesModel, ExportModel, STATUSES } from './export-monitor.model';
import { TmExportMonitorService } from './export-monitor.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, shareReplay, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'tm-export-monitor',
  templateUrl: 'export-monitor.component.html',
  styleUrls: ['export-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmExportMonitorComponent implements AfterViewInit {
  @ViewChild(IwPopoverDirective, { static: false }) public popover: IwPopoverDirective;

  public complete = false;
  public loadingNewLength = this._exportService.sharedData.pipe(
    map((data) => this.getExecutingNewLength(data)),
    tap((length) => this.toggleComplete(length === 0)),
    shareReplay(1)
  );
  public itemsCount = this._exportService.sharedData.pipe(
    map((data) => data.length),
    tap((count) => {
      if (!count && this.popover) {
        this.popover.hide();
      }
    })
  );

  private popoverVisible = false;

  constructor(
    private _exportService: TmExportMonitorService,
    private _translate: TranslateService,
    private _notify: IwNotificationsService,
    private _channelService: TmChannelService
  ) {
    this._channelService
      .getUserChannel('object_report')
      .pipe(untilDestroyed(this))
      .subscribe(this._readObjectReportMessage.bind(this));
    this._channelService
      .getUserChannel('object_reporter')
      .pipe(untilDestroyed(this))
      .subscribe(this._readObjectReporterMessage.bind(this));
    this._channelService
      .getUserChannel('reporter')
      .pipe(untilDestroyed(this))
      .subscribe(this._readReporterMessage.bind(this));
  }

  public ngAfterViewInit() {
    this.popover.isVisible.pipe(untilDestroyed(this)).subscribe((visible: boolean) => (this.popoverVisible = visible));
  }

  public toggleComplete(bool: boolean): void {
    this.complete = bool;
  }

  private getExecutingNewLength(data: ExportModel[]) {
    return data.filter((item) => {
      return item.STATUS === STATUSES.EXECUTING || item.STATUS === STATUSES.NEW;
    }).length;
  }

  private _readObjectReportMessage({ meta }: { meta: any }): void {
    this._exportService.updateExportStatus(meta);
  }

  private _readObjectReporterMessage({
    error,
    meta,
    data,
    state,
  }: {
    error: string;
    meta: any;
    data: ExportFilesModel;
    state: string;
  }): void {
    if (state === 'data') {
      this._exportService.updateExportFiles(data);

      if (this.popoverVisible) {
        return;
      }

      this._notify.success(
        this._translate.instant('events.exportMonitor.export'),
        `${this._translate.instant('events.exportMonitor.export_done', { selection: data.filename })}
          [<a href='/api/object/reportFile?report_id=${data.report_id}' target='_blank'>
          ${this._translate.instant('events.exportMonitor.download')}
          </a>]`,
        {
          timeOut: 0,
        }
      );
    } else if (state === 'error') {
      let query_name = meta.query;

      switch (error) {
        case 'Error file open':
          error = this._translate.instant('events.exportMonitor.error_on_file_open', { selection: query_name });
          break;
        case 'File type is not supported':
          error = this._translate.instant('events.exportMonitor.error_file_type', { selection: query_name });
          break;
        case 'low_disk_free_space':
          error = this._translate.instant('events.exportMonitor.low_disk_free_space');
          break;
        default:
          if (query_name) {
            error = this._translate.instant('events.exportMonitor.export_fail', { selection: query_name });
          } else {
            error = this._translate.instant('events.exportMonitor.export_internal_error');
          }
      }
      this._exportService.updateExportStatus(meta);

      this._notify.error(this._translate.instant('events.exportMonitor.export'), error, {
        timeOut: 0,
      });
    }
  }

  private _readReporterMessage({ data, state }: { data: ExportModel; state: string }): void {
    if (state === 'data') {
      this._exportService.updateExportStatus(data);

      if (this.popoverVisible) {
        return;
      }

      this._notify.success(
        this._translate.instant('events.exportMonitor.report'),
        `${this._translate.instant('events.exportMonitor.report_done', { name: data.DISPLAY_NAME })}
        [<a href='/public/${data.HASH}.pdf' target='_blank'>pdf</a>]
        [<a href='/public/${data.HASH}.html' target='_blank'>html</a>]`,
        {
          timeOut: 0,
        }
      );
    } else if (state === 'error') {
      this._exportService.updateExportStatus(data);

      if (this.popoverVisible) {
        return;
      }

      this._notify.error(
        this._translate.instant('events.exportMonitor.report'),
        this._translate.instant('events.exportMonitor.undefined_error'),
        {
          timeOut: 0,
        }
      );
    }
  }
}
