import { NgModule } from '@angular/core';
import { TmEventsIconComponent } from './tm-event-icon.component';
import { TmIconModule } from '../icons/tm-icon.module';
import { TmEventIconService } from './tm-event-icon.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TmEventsIconComponent],
  imports: [TmIconModule, CommonModule],
  exports: [TmEventsIconComponent],
  providers: [TmEventIconService],
})
export class TmEventIconModule {}
