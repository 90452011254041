import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '../dataloader';
import { Perimeter } from '../../typings/generated/perimeter';

@Injectable({
  providedIn: 'root',
})
export class TmPerimetersSystemListService extends TmCollectionLoader<Perimeter> {
  public readonly src = '/api/perimeterSystemList';
  public idAttribute = 'PERIMETER_ENTRY_ID';
}
