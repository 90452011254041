<ng-container *ngIf="categories | loadingStatus | async as loadingStatus">
  <tm-spinner *ngIf="loadingStatus.loading"></tm-spinner>
  <iw-tree
    [data]="loadingStatus.value || []"
    [options]="{ indeterminate: true }"
    [(expanded)]="expanded"
    [selected]="checked"
    (selectedChange)="checkedChange($event)"
  >
  </iw-tree>
</ng-container>
