import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { ICheckboxEvent } from '@platform/shared';

@Component({
  selector: 'tm-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmeCheckboxComponent {
  @Input('checked') checked = false;
  @Input('disabled') disabled = false;
  @Output() public entityClick: EventEmitter<ICheckboxEvent> = new EventEmitter();
}
