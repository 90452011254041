import { Input, Output, EventEmitter, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { ConditionValueObject } from '../../../plugins/events/events.model';

@Directive({})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractTabComponent implements OnDestroy {
  public destroy$ = new Subject<void>();

  @Input() tabParams: any;
  @Input() public selected: ConditionValueObject[];
  @Output() public selectedChange = new EventEmitter<ConditionValueObject[]>();

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
