import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { SystemListItem, SystemListItemCommon } from 'plugins/lists-resources/generated/resources';

@Injectable({
  providedIn: 'root',
})
export class TmResourcesService extends TmCollectionLoader<SystemListItem, SystemListItemCommon> {
  public readonly src = '/api/systemListItem';
  public idAttribute = 'LIST_ITEM_ID';
}
