import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'tm-full-width-cell',
  template: `
    <div class="full-width-panel">
      <div class="full-width-summary">
        <span class="full-width-title">{{ data?.fullWidthRowText }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./full-width-cell-renderer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullWidthCellComponent implements ICellRendererAngularComp {
  public data: any;

  agInit(params: ICellRendererParams): void {
    this._updateData(params.node.data);
  }

  public refresh(params: ICellRendererParams): boolean {
    this._updateData(params.node.data);
    return true;
  }

  private _updateData(data: any) {
    this.data = data;
  }
}
