import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmSpinnerComponent } from './spinner.component';
import { registerWebComponent } from '../custom-elements';

@NgModule({
  imports: [CommonModule],
  declarations: [TmSpinnerComponent],
  exports: [TmSpinnerComponent],
})
export class TmSpinnerModule {
  constructor() {
    registerWebComponent('tme-spinner', TmSpinnerComponent);
  }
}
