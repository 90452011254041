import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable({
  providedIn: 'root',
})
export class TmLdapStatusApiService extends TmCollectionLoader<TmApi.ldapStatus.CollectionItem>
  implements TmGrid.grid.ApiService {
  public readonly src = '/api/ldapStatus';
  public idAttribute = 'IDENTITY_STATUS_ID';

  public isModelEditable(data: TmApi.ldapStatus.CollectionItem[] | TmApi.ldapStatus.CollectionItem) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.every((item) => item.EDITABLE === 1);
  }
}
