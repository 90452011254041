<div class="event-wrapper {{ event.VIOLATION_LEVEL | lowercase }}">
  <div class="event">
    <span>
      <tm-event-type-icon
        [name]="event.event.attributes.name"
        [protocol]="event.protocol?.attributes.name"
        [mnemo]="event.event.attributes.mnemo"
        [serviceCode]="event.SERVICE_CODE"
      ></tm-event-type-icon>
      <span class="event-content">
        ID: {{ event.OBJECT_ID }}, <span [tmLocalizeDate]="event.CAPTURE_DATE" [dateOutputFormat]="dateFormat"></span>
      </span>
    </span>

    <div class="event-attachments">
      <div
        *ngFor="let attachment of attachmentService.filterAttachments(event.objectContentFilenames)"
        class="event-attachment {{ attachmentService.isSelected(attachment) ? '_active' : '' }}"
        (click)="attachmentService.select(attachment)"
      >
        <span class="event-attachment-file">{{ getFileName(attachment) | async }}</span>
        <a class="event-attachment-file" href="{{ downloadUrl(attachment) }}"
          ><tm-icon [size]="'16'" svgIcon="downloadAttachment"></tm-icon
        ></a>
        <span class="event-attachment-size" *ngIf="attachment.CONTENT_SIZE"
          >({{ attachment.CONTENT_SIZE | bytesWithUnit }})</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template #eventPopover let-name="name" let-protocol="protocol">
  <div>{{ 'events.eventType' | translate }}: {{ name }}{{ protocol ? ',' : '' }}</div>
  <div *ngIf="protocol">{{ 'events.protocol' | translate }}: {{ protocol }}</div>
</ng-template>
