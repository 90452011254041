import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { AbstractTabComponent } from './abstract-tab-component';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CategoryTabService } from './category-tab.service';
import { ConditionValueObject } from '../../../plugins/events/events.model';
import { IwTreeNode, IwTreeSelected } from '@platform/shared';

@UntilDestroy()
@Component({
  selector: 'tm-category-tab',
  templateUrl: 'category-tab.component.html',
  styleUrls: ['category-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CategoryTabService],
})
export class CategoryTabComponent extends AbstractTabComponent implements OnInit {
  public static scope = 'category' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.category';

  public categories: Observable<IwTreeNode[]>;

  public checked: IwTreeSelected[] = [];
  public expanded: string[] = [];

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: CategoryTabComponent.scope,
  };

  constructor(public service: TmSearchService, private categoryService: CategoryTabService) {
    super();
  }

  public ngOnInit() {
    this.categories = this.service
      .get({
        params: {
          type: 'query',
          scopes: CategoryTabComponent.scope,
          'filter[TYPE]': 'term',
        },
      })
      .pipe(
        map((response) => this.categoryService.mapParentToChildren(response.data.category, this.selected)),
        map((result) => {
          this.checked = result.selected;
          this.expanded = result.expanded;
          return result.list;
        })
      );
  }

  public async checkedChange(newChecked: IwTreeSelected[]) {
    const currentlySelected = this.selected.filter((item) => item.TYPE !== CategoryTabComponent.scope);

    const items = await firstValueFrom(
      this.service.getItemsByScopeAndId({ category: newChecked.map((item) => item.id || '') })
    );
    const newSelected =
      items.category?.map(
        (item) =>
          <ConditionValueObject>{
            DATA: item.CATEGORY_ID,
            NAME: item.DISPLAY_NAME,
            IS_DELETED: item.IS_DELETED,
            TYPE: CategoryTabComponent.scope,
          }
      ) || [];

    this.selectedChange.emit([...currentlySelected, ...newSelected]);
  }
}
