import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader/stateful.service';

/**
 * API's versions service
 */
@Injectable({
  providedIn: 'root',
})
export class TmVersionService extends TmStatefulService<TmApi.GetResponse<TmShared.version.Model>> {
  public src = '/api/checkVersion';
}
