import { NgModule } from '@angular/core';

import { LoadingPipe } from './loading.pipe';

@NgModule({
  declarations: [LoadingPipe],
  exports: [LoadingPipe],
})
export class LoadingModule {
  constructor() {}
}
