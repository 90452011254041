<div class="query-row tech-width">
  <label *ngIf="mode === 'lite'" class="query-item-label" translate>@tm-shared.events.analysis-label</label>

  <ng-content></ng-content>

  <tm-spinner *ngIf="loading"></tm-spinner>

  <tm-search-select
    *ngIf="!loading"
    class="search-select-field"
    [control]="formControl"
    [modalComponents]="components"
    [searchParams]="params"
    modalHeader="{{ modaltitle }}"
    [additionalTypesToSelect]="additionalItems | async"
  ></tm-search-select>
</div>

<div *ngIf="textObjectField | async as masked" class="query-row">
  <span class="query-item-label"
    >{{ '@tm-shared.events.text-object-note' | translate }}
    <tm-icon svgIcon="help" style="margin-left: 5px;" *ngIf="masked.NOTE" title="{{ masked.NOTE }}"></tm-icon
  ></span>

  <input
    class="tm-input text-object-field"
    [ngClass]="{ 'tm-input-invalid': textObjectControl.invalid }"
    iwPopover="{{ textObjectControl.invalid ? ('@tm-shared.events.text-object-invalid' | translate) : '' }}"
    [tmMask]="masked.MASK!"
    [formControl]="textObjectControl"
  />
</div>
