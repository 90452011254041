import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  forwardRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TmTabComponent } from '../tab/tab.component';

@UntilDestroy()
@Component({
  selector: 'tm-tabset',
  templateUrl: './tab-set.component.html',
  styleUrls: ['./tab-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmTabsetComponent implements AfterContentInit, OnInit {
  @ContentChildren(forwardRef(() => TmTabComponent))
  public tabs: QueryList<TmTabComponent>;

  @Input() public activeIndex = 0;
  @Input() public saveUrl = false;
  @Output() public activeIndexChange = new EventEmitter<number>();
  private previousActive: number;
  private activeTab = 'active_tab';

  constructor(private _cd: ChangeDetectorRef, private _route: ActivatedRoute, private _router: Router) {}

  public ngOnInit(): void {
    if (this.saveUrl) {
      this._route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
        const index = params.get(this.activeTab) || 0;
        this.activeIndex = +index;
      });
    }
  }

  public ngAfterContentInit() {
    if (this.tabs.length) {
      this.selectTab(this.activeIndex);
    }
  }

  public markForCheck() {
    this._cd.markForCheck();
  }

  public selectTab(i: number): void {
    const tabs = this.tabs.toArray();
    if (tabs[i].disabled) {
      return;
    }
    this.previousActive = this.activeIndex;
    this.activeIndex = i;
    this.activeIndexChange.emit(this.activeIndex);
    tabs[this.previousActive].calcActiveState();
    tabs[this.activeIndex].calcActiveState();

    if (this.saveUrl) {
      this._router.navigate([], {
        relativeTo: this._route,
        queryParamsHandling: 'merge',
        queryParams: { [this.activeTab]: i },
      });
    }
  }
}
