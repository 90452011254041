import { TmSearchSelectModule } from './search-select.module';
import { TmeQuerySendersComponent } from './tme-wrappers/tme-query-senders.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TmePerimetersComponent } from './tme-wrappers/tme-perimeters.component';
import { TmeWorkstationComponent } from './tme-wrappers/tme-workstation.component';
import { TmeWebResoucesComponent } from './tme-wrappers/tme-resources.component';
import { TmeApplicationsComponent } from './tme-wrappers/tme-applications.component';
import { TmeTagsComponent } from './tme-wrappers/tme-tags.component';
import { TmSpinnerModule } from '../spinner';
import { TmeTechComponent } from './tme-wrappers/tme-tech.component';
import { TmFormElementsModule } from '../form-elements/form-elements.module';
import { TmIconModule } from '../icons/tm-icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { IwPopoverModule } from '@platform/shared';
import { registerWebComponent } from '../custom-elements';

@NgModule({
  imports: [
    CommonModule,
    TmSearchSelectModule,
    TranslateModule,
    TmSpinnerModule,
    TmFormElementsModule,
    TmIconModule,
    ReactiveFormsModule,
    IwPopoverModule,
  ],
  exports: [TmeQuerySendersComponent],
  declarations: [
    TmeQuerySendersComponent,
    TmePerimetersComponent,
    TmeWorkstationComponent,
    TmeWebResoucesComponent,
    TmeApplicationsComponent,
    TmeTagsComponent,
    TmeTechComponent,
  ],
})
export class TmSearchSelectElementsModule {
  constructor() {
    registerWebComponent('tme-query-senders', TmeQuerySendersComponent);
    registerWebComponent('tme-perimeters', TmePerimetersComponent);
    registerWebComponent('tme-workstation', TmeWorkstationComponent);
    registerWebComponent('tme-resources', TmeWebResoucesComponent);
    registerWebComponent('tme-applications', TmeApplicationsComponent);
    registerWebComponent('tme-tags', TmeTagsComponent);
    registerWebComponent('tme-tech', TmeTechComponent);
  }
}
