import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '../dataloader';
import { Perimeter } from '../../typings/generated/perimeter';

@Injectable({
  providedIn: 'root',
})
export class TmPerimetersContactsService extends TmCollectionLoader<Perimeter> {
  public readonly src = '/api/perimeterContact';
  public idAttribute = 'CONTACT_ID';
}
