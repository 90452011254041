import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IconName } from '../../../../typings/generated/icons';

@Component({
  templateUrl: 'text-with-icon-cell.component.html',
  styleUrls: ['./text-with-icon-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextWithIconCellComponent implements AgRendererComponent {
  public data: {
    iconShowCondition?: boolean;
    value?: string;
    icon?: IconName;
    iconPngPath?: string;
  } = { iconShowCondition: false };

  public agInit(data: any): void {
    this._updateData(data);
  }

  public refresh(data: any): boolean {
    this._updateData(data);
    return true;
  }

  private _updateData(data: any): void {
    this.data = data;
  }
}
