import { Component } from '@angular/core';

/**
 * Show pre-formatted string.
 * It may be even more useful if we add optional "copy to clipboard" icon or other features
 */
@Component({
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./pre.component.scss'],
  selector: 'tm-pre',
})
export class TmPreComponent {}
