import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { TmSystemInfoService } from '@tm-shared/systeminfo';
import { TmVersionService } from '@tm-shared/version';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import aboutLogo from '../../../../assets/ru-logo-about.png';

@Component({
  templateUrl: './tm-systeminfo.component.html',
  styleUrls: ['./tm-systeminfo.component.scss'],
  selector: 'tm-systeminfo',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSystemInfoComponent implements OnInit, OnDestroy {
  public aboutLogo = aboutLogo;

  @Input() public copyLink = 'http://infowatch.ru';
  @Input() public copyLinkText = 'www.infowatch.ru';
  @Input() public emailCommon = 'info@infowatch.ru';
  @Input() public emailSupport = 'support@infowatch.ru';
  @Input() public phone = '+7 (495) 22-900-22';
  @Input() public productType?: string;
  @Input() public version?: string;
  @Input() public yearFrom: string | number = 2003;
  @Input() public yearTo: string | number = new Date().getFullYear();

  private _destroy$: Subject<void> = new Subject();

  constructor(
    private _cd: ChangeDetectorRef,
    @Optional() private _systeminfo?: TmSystemInfoService,
    @Optional() private _version?: TmVersionService
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit() {
    if (!this.productType && this._systeminfo) {
      this._systeminfo
        .getLocalizedProductVersion()
        .pipe(takeUntil(this._destroy$))
        .subscribe((product) => {
          this.productType = product;
          this._cd.markForCheck();
        });
    }

    if (!this.version && this._version) {
      this._version
        .getWithRetries()
        .pipe(takeUntil(this._destroy$))
        .pipe(map((response) => response.data))
        .subscribe((data) => {
          this.version = data.dbVersion;
          this._cd.markForCheck();
        });
    }
  }
}
