import { Injectable } from '@angular/core';
import * as patterns from './patterns/patterns';

// класс для кофе
@Injectable({
  providedIn: 'root',
})
export class TmHelpersService {
  public readonly patterns = patterns;
}
