import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { FormatTypeInfo } from '../../typings/generated/bookworm';

@Injectable({
  providedIn: 'root',
})
export class TmBookwormFormatTypesService extends TmStatefulService<TmApi.GetResponse<FormatTypeInfo[]>> {
  public src = '/api/bookworm/formatTypes';
}
