import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TmConfigLocalService } from '../services';
import { IwPopoverOptions, ModalDialogRef } from '@platform/shared';
import { TmPolicyApiService } from '../../../@tm-shared/api-services';
import { finalize, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigDataGet, Status } from '../generated/config';
import { TranslateService } from '@ngx-translate/core';
import { getFlagsByConfig } from '../common';

export type Mode = 'save' | 'revert' | 'apply';

@UntilDestroy()
@Component({
  templateUrl: './config-modal.component.html',
  styleUrls: ['config-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TmConfigModalComponent implements OnInit {
  @Input() mode: Mode = 'save';

  @Input() processing = false;

  public loading = false;

  public hideSaveButton: boolean;

  public disableApply = false;
  public disableApplyReason = '';

  public disableRevert = false;
  public disableRevertReason = '';

  constructor(
    private config: TmConfigLocalService,
    private ref: ModalDialogRef<TmConfigModalComponent>,
    private policyApi: TmPolicyApiService,
    private t: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.config.dataWithUpdates$.pipe(untilDestroyed(this)).subscribe(this.handleConfigChanges);
  }

  private handleConfigChanges = (config: ConfigDataGet) => {
    if (config.STATUS === Status.FREE) {
      this.ref.close(undefined);
    }

    const obj = getFlagsByConfig(config, this.t);
    this.disableRevert = obj.disableRevert;
    this.disableRevertReason = obj.disableRevertReason;
    this.disableApply = obj.disableApply;
    this.disableApplyReason = obj.disableApplyReason;
    this.hideSaveButton = obj.hideSaveButton;
    this.cd.markForCheck();
  };

  public apply() {
    this.loading = true;
    this.policyApi
      .publish()
      .pipe(
        switchMap(() => this.config.setStatus(Status.LOCKED)),
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public revert() {
    this.loading = true;
    this.config
      .setStatus(Status.FREE)
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public save() {
    this.loading = true;
    this.config
      .setStatus(Status.SAVED)
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe(() => this.ref.close(undefined));
  }

  public cancel() {
    this.ref.dismiss(undefined);
  }
}
