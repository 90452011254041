import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TmLdapGroupHierarchyApiService, TmSearchService } from '@tm-shared/api-services';
import { AbstractTabComponent } from './abstract-tab-component';
import { map, skip, takeUntil } from 'rxjs/operators';
import { TmGridComponent } from '../../grid';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { TranslateService } from '@ngx-translate/core';
import { getValueByScope } from '../../api-services/functions';
import { ConditionValueObject } from '../../../plugins/events/events.model';
import { IwTreeNode, IwTreeSelected } from '@platform/shared';
import { firstValueFrom } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { GroupTabService } from './group-tab.service';

@Component({
  selector: 'tm-group-tab',
  templateUrl: 'group-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GroupTabService],
})
export class GroupTabComponent extends AbstractTabComponent implements AfterViewInit, OnInit {
  public static scope = 'group' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.group';

  public config: TmGrid.grid.TableConfigParams = {
    scopes: 'group',
    type: 'query',
    'filter[IS_DELETED]': '1',
  };

  public startItems = this.ldapGroupService
    .get()
    .pipe(map((response) => this.groupTabService.mapGroupToTreeNode(response.data)));
  public expanded: string[] = [];
  public checked: IwTreeSelected[] = [];

  public columnDefs: ColDef[] = [
    {
      width: 40,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapGroup.displayName'),
    },
  ];

  @ViewChild(TmGridComponent) public grid: TmGridComponent<TmApi.ldapGroup.CollectionItem>;

  private deletedForEmit: ConditionValueObject[] = [];
  private existingForEmit: ConditionValueObject[] = [];

  public ngOnInit() {
    const selected = this.selected.filter((item) => item.TYPE === 'group');
    this.deletedForEmit = selected.filter((item) => item.IS_DELETED);
    this.existingForEmit = selected.filter((item) => !item.IS_DELETED);
    this.getCheckedAndExpanded();
  }

  public ngAfterViewInit() {
    this.grid.selectById(this.deletedForEmit.map((item) => item.DATA));
    this.setGridListeners();
  }

  private async getCheckedAndExpanded() {
    const checked = this.existingForEmit.map((item) => ({ id: item.DATA }));
    const data = await firstValueFrom(this.ldapGroupService.getItemsByIds(checked.map((item) => item.id || '')));
    const pathsToGroups = new Set<string>();
    this.expanded.forEach((id) => pathsToGroups.add(id));
    this.checked = data.map((group) => {
      const paths = (group.ID_PATH || group.ID_NAME_PATH.ID_PATH).split('\\').filter(Boolean);
      paths.reduce((parts: string[], part: string) => {
        const newParts = [...parts, part];
        const path = newParts.join('.');
        pathsToGroups.add(path);
        return newParts;
      }, []);

      return {
        path: paths.join('.'),
        checked: true,
      };
    });
    this.expanded = Array.from(pathsToGroups);
    this.cd.markForCheck();
  }

  public async onTreeCheckedChange(newChecked: IwTreeSelected[]) {
    const ids = newChecked.map((item) => {
      if (item.id) {
        return item.id;
      } else {
        const paths = item.path?.split('.');
        return paths?.slice(paths.length - 1, paths.length)?.[0] || '';
      }
    });
    let items = await firstValueFrom(this.ldapGroupService.getItemsByIds(ids));
    this.existingForEmit = items.map((item) => {
      return {
        TYPE: GroupTabComponent.scope,
        DATA: item.GROUP_ID,
        NAME: item.DISPLAY_NAME,
      };
    });
    this.emitChangedSelection();
  }

  private setGridListeners() {
    const startingSelected = this.selected.filter((item) => item.TYPE === 'group');
    this.grid.selectedIds.pipe(skip(1), takeUntil(this.destroy$)).subscribe((currentlySelectedIds) => {
      this.deletedForEmit = currentlySelectedIds.map((id) => {
        const itemFromGrid = this.grid
          .getSelected()
          .find((item) => (item ? item[this.grid.idAttribute as keyof typeof item] === id : false));
        const previousSelected = startingSelected.find((item) => item.DATA === id);
        return itemFromGrid
          ? getValueByScope(itemFromGrid, GroupTabComponent.scope).data.mapped
          : (previousSelected as ConditionValueObject);
      });
      this.emitChangedSelection();
    });
  }

  private emitChangedSelection() {
    const allItems = this.selected.filter((item) => item.TYPE !== 'group');
    allItems.push(...this.deletedForEmit, ...this.existingForEmit);
    this.selectedChange.emit(allItems);
  }

  constructor(
    public ldapGroupService: TmLdapGroupHierarchyApiService,
    public groupTabService: GroupTabService,
    public searchService: TmSearchService,
    private _t: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  public getChildren = (node: IwTreeNode) => {
    return this.groupTabService.getChildren(node);
  };
}
