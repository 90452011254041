<iw-select
  *ngIf="control"
  class="selectControl"
  placeholder="{{ placeholder || '@tm-shared.search-select.form.search' | translate }}"
  [formControl]="control"
  [multiple]="true"
  [consistentModel]="true"
  [searchable]="true"
  [server]="getSearchCallback()"
  data-qa="scopeSelectInput"
  [labelTemplate]="labelTemplate"
  [optionTemplate]="optionTemplate"
></iw-select>

<ng-template #labelTemplate let-item="item" let-unselectItem="unselectItem">
  <div class="value-wrapper default" [class.disabled]="item.disabled" *ngIf="item.data.data as data">
    <span class="value-icon">
      <ng-container *ngTemplateOutlet="iconTemplate; context: { type: data.mapped.TYPE }"></ng-container>
      <ng-container *ngTemplateOutlet="helpTextTemplate; context: { type: data.mapped.TYPE }"></ng-container>
    </span>
    <span class="value-label" [title]="item.label">
      <span [ngClass]="{ 'deleted-item': data.mapped.IS_DELETED }">{{ item.label }}</span>
    </span>
    <span class="value-icon right" aria-hidden="true" (click)="unselectItem(item)">
      <tm-icon svgIcon="close"></tm-icon>
    </span>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <span
    data-qa="option-template"
    class="option-label select-option-item"
    *ngIf="item.data.data as data"
    [title]="item.label"
  >
    <ng-container *ngTemplateOutlet="iconTemplate; context: { type: data.mapped.TYPE }"></ng-container>
    <ng-container *ngTemplateOutlet="colorTemplate; context: { color: data.pure?.COLOR }"></ng-container>
    <ng-container *ngTemplateOutlet="helpTextTemplate; context: { type: data.mapped.TYPE }"></ng-container>
    <span [ngClass]="{ 'deleted-item': data.mapped.IS_DELETED }">
      <span *ngIf="data.pure?.SOURCE">{{ data.pure?.SOURCE | uppercase }}:</span>
      {{ item.label }}
    </span>
    <br />
    <div class="personHelpers" style="overflow: hidden;">
      <div *ngIf="data.pure?.DNSROOT || data.pure?.DNSHOSTNAME || data.pure?.DOMAIN_NAME" class="personHelpers">
        {{ '@tm-shared.search-select.options.domain' | translate }}:
        {{ data.pure?.DNSROOT || data.pure?.DNSHOSTNAME || data.pure?.DOMAIN_NAME }}
      </div>
      <div *ngIf="data.pure?.SAMACCOUNTNAME" class="personHelpers">
        {{ '@tm-shared.search-select.options.account' | translate }}: {{ data.pure?.SAMACCOUNTNAME }}
      </div>
      <div *ngIf="data.pure?.LOTUS" class="personHelpers">
        {{ '@tm-shared.search-select.options.account' | translate }}: {{ (data.pure?.LOTUS?.split('@'))[0] }}
      </div>
    </div>
    <span
      *ngIf="
        additionalTypesToSelect &&
        searchSelectService.getSuggestionByMnemo(data.mapped.TYPE, additionalTypesToSelect) as suggestion
      "
      class="suggestion"
      >{{ suggestion }}</span
    >
  </span>
</ng-template>

<ng-template #iconTemplate let-type="type">
  <ng-container *ngIf="searchSelectService.getIconByType(type) as iconName">
    <img *ngIf="iconName.includes('.png'); else svgIcon" class="iconStyle" src="/img/icons/{{ iconName }}" />
    <ng-template #svgIcon>
      <iw-icon *ngIf="iconName" class="iconStyle" [svgIcon]="iconName"></iw-icon>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #colorTemplate let-color="color">
  <ng-container *ngIf="color">
    <span [style.backgroundColor]="color" class="colorIcon"></span>
  </ng-container>
</ng-template>
<ng-template #helpTextTemplate let-type="type">
  <ng-container *ngIf="typeToShowHelpText.includes(type)">
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.text_object -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.form -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.stamp -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.table -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.category -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.fingerprint -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.graphic -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.card -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.long.autoling -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.text_object -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.form -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.stamp -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.table -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.category -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.fingerprint -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.graphic -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.card -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.short.autoling -->
    <span class="showLong">{{ '@tm-shared.search-select.form.techHelpers.long.' + type | translate }}</span>
    <span class="showShort" title="{{ '@tm-shared.search-select.form.techHelpers.long.' + type | translate }}">
      {{ '@tm-shared.search-select.form.techHelpers.short.' + type | translate }}
    </span>
    :
  </ng-container>
</ng-template>

<button
  class="iconButton openModal"
  iw-button
  [disabled]="control?.disabled || false"
  type="button"
  (click)="openDialog()"
  data-qa="openDialogTrigger"
>
  <tm-icon svgIcon="create"></tm-icon>
</button>
