import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { SystemList1, SystemListCommon } from 'plugins/lists-resources/generated/resources-group';

@Injectable({
  providedIn: 'root',
})
export class TmResourcesGroupService extends TmCollectionLoader<SystemList1, SystemListCommon> {
  public readonly src = '/api/systemList';
  public idAttribute = 'LIST_ID';
}
