import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmDescriptionModule } from './description/description.module';
import { TmFillerTextComponent } from './filler-text/filler-text.component';
import { TmOverlayModule } from './overlay';
import { TmPanelComponent } from './panel';
import { TmPolicyModule } from './policy/policy.module';
import { TmSpinnerModule } from './spinner';
import { IwSharedModule } from '@platform/shared';
import { TmIconModule } from './icons/tm-icon.module';
import { TmTumblerCheckboxModule } from './tm-tumbler-indicator/tumbler-checkbox.module';
import { EmptyComponent } from './empty-component/empty.component';
/**
 * Core services, * should be exported once by TM plugin;
 */
@NgModule({
  imports: [
    IwSharedModule,
    TmOverlayModule,
    TmSpinnerModule,
    TmPolicyModule,
    TmIconModule,
    TmDescriptionModule,
    CommonModule,
    TmTumblerCheckboxModule,
  ],
  declarations: [TmPanelComponent, TmFillerTextComponent, EmptyComponent],
  exports: [TmPanelComponent, TmFillerTextComponent, TmIconModule, EmptyComponent],
})
export class TmSharedModule {}
