import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Perimeter } from '../../typings/generated/perimeter';

@Injectable({
  providedIn: 'root',
})
export class TmPerimetersPersonsService extends TmCollectionLoader<Perimeter> {
  public readonly src = '/api/perimeterPerson';
  public idAttribute = 'PERIMETER_ENTRY_ID';
}
