<tm-event-icon
  [iwPopover]="eventPopover"
  [container]="'body'"
  [popoverStyle]="{
    padding: 0
  }"
  [triggers]="'mouseenter:mouseleave'"
  [context]="{
    name: name,
    protocol: protocol
  }"
  [className]="'eventIcon'"
  [mnemo]="mnemo"
  [serviceCode]="serviceCode"
></tm-event-icon>
<ng-template #eventPopover let-name="name" let-protocol="protocol">
  <div>{{ 'events.eventType' | translate }}: {{ name }}{{ protocol ? ',' : '' }}</div>
  <div *ngIf="protocol">{{ 'events.protocol' | translate }}: {{ protocol }}</div>
</ng-template>
