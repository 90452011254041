import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'tm-web-resources-tab',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebResourcesTabComponent extends TableTabComponent {
  public static scope = 'resource' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.resource';

  public scopeToEmitOnChange = WebResourcesTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: WebResourcesTabComponent.scope,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public columnDefs = <ColDef[]>[
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'DISPLAY_NAME',
      sort: 'asc',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
    },
  ];
}
