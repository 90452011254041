import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmPrivilegesModule } from '@tm-shared/privileges/privileges.module';
import { registerWebComponent } from '@tm-shared/custom-elements';

import ru from '../settings-license/i18n/settings-license.ru.json';
import en from '../settings-license/i18n/settings-license.en.json';
import { TmLicenseNotificationComponent } from './license-notification/license-notification.component';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

@NgModule({
  imports: [CommonModule, IwSharedModule, TranslateModule, TmPrivilegesModule, TmIconModule],
  exports: [TmLicenseNotificationComponent],
  declarations: [TmLicenseNotificationComponent],
})
export class TmLicenseElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
    registerWebComponent('tme-license-notification', TmLicenseNotificationComponent);
  }
}
