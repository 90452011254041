import { Injectable } from '@angular/core';
import { CategoryListItem } from '../../../typings/generated/technology-category';
import { CategoryTabComponent } from './category-tab.component';
import { ConditionValueObject } from '../../../plugins/events/events.model';
import { IwTreeNode } from '@platform/shared';
import { CategoryApiService } from '../../api-services';

@Injectable()
export class CategoryTabService {
  constructor(private categoryService: CategoryApiService) {}
  public mapParentToChildren(items: CategoryListItem[] | undefined, selectedAll: ConditionValueObject[]) {
    const selected = selectedAll
      .filter((item) => item.TYPE === CategoryTabComponent.scope)
      .map((item) => ({ id: item.DATA, checked: true }));
    const list: IwTreeNode[] = this.categoryService.convertListToTree(items || []);
    return {
      list,
      selected,
      expanded: list.map((item) => item.id),
    };
  }
}
