import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ColDef } from 'ag-grid-community';

@UntilDestroy()
@Component({
  selector: 'tm-application-tab',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationTabComponent extends TableTabComponent implements OnInit {
  public static scope = 'application' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.application';

  public scopeToEmitOnChange = ApplicationTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    scopes: ApplicationTabComponent.scope,
  };
  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public columnDefs: ColDef[] = [
    {
      width: 30,
      field: 'checkbox',
      headerName: '',
      cellRenderer: CheckboxCellComponent,
    },
    {
      field: 'APPLICATION_ID',
      sort: 'asc',
      resizable: true,
      sortable: true,
      headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
    },
  ];

  public ngOnInit() {
    this.config = {
      ...this.config,
      ...(this.tabParams?.query || {}),
    };
  }
}
