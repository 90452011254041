import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TmFormComponent } from '@tm-shared/form';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TmEventsSearchWidgetService } from './events-search-widget.service';

/**
 * Event search widget
 * PR: https://wiki.infowatch.ru/x/kQ3fBw
 */
@Component({
  selector: 'tm-events-search-widget',
  templateUrl: 'events-search-widget.component.html',
  styleUrls: ['events-search-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmEventSearchWidgetComponent extends TmFormComponent implements OnInit {
  public readonly maxInputLength: number = 2048;

  public form: FormGroup;

  constructor(private _fb: FormBuilder, private _service: TmEventsSearchWidgetService) {
    super();
  }

  public ngOnInit() {
    this.form = this._fb.group({
      searchInput: [
        this._service.getIdsFromPageUrl(),
        [Validators.maxLength(this.maxInputLength), Validators.pattern(/[0-9,]/), Validators.required],
      ],
    });

    this.form.controls.searchInput.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => this._patch(value, true));

    this._service.clear$.pipe(takeUntil(this._destroyed$)).subscribe(() => this._patch('', true));
  }

  protected _onSubmit() {
    this._service.searchBy(this.form.value.searchInput);
    return of(true);
  }

  private _patch(value: string | null, doNotEmit = false): void {
    this.form.patchValue(
      {
        searchInput: value ? this._service.extractIds(value.slice(0, this.maxInputLength)) : value,
      },
      {
        emitEvent: !doNotEmit,
      }
    );
  }
}
