import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getIdStringByScopeString } from '@tm-shared/api-services/functions';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { Observable, ReplaySubject, Subject, fromEvent, merge, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { GridInternalLogicService } from './grid-internal-logic.service';
import { GridInternalSelectionsService } from './grid-internal-selections.service';
import { GridLocalApiService } from './grid-local-api.service';
import { TmGridState } from './tm-grid-state';
import { FullWidthCellComponent } from './cell-renderers/full-width-cell/full-width-cell-renderer';

const PAGINATION_HEIGHT = 40;

@Component({
  selector: 'tm-grid',
  templateUrl: './tm-grid.component.html',
  styleUrls: ['./tm-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridInternalLogicService, GridInternalSelectionsService, TmGridState, GridLocalApiService],
})
export class TmGridComponent<Model> implements OnDestroy, OnInit {
  /**
   * Set ag-grid options
   */
  @Input() public set gridOptions(gridOptions: GridOptions | null | undefined) {
    if (gridOptions) {
      this.mergeGridOptions(gridOptions);
    }
  }
  @Input() public set columnDefs(cols: ColDef[] | null | undefined) {
    this._columnDefs = cols;
    if (cols) {
      const hasCheckbox = cols[0].field === 'checkbox';
      this._multiPageSelectable = hasCheckbox;
    }
  }

  public get columnDefs() {
    return this._columnDefs;
  }

  private _columnDefs: ColDef[] | null | undefined;

  public get options(): GridOptions {
    return this._gridOptions;
  }

  /**
   * set additional parameters for url requests made by table.
   * 'order by' is controlled by table, filter is controlled by methods or by GridFilterDirective
   * @example with[]=users
   * @example scopes=tag
   */
  @Input() public set tableConfig(params: TmGrid.grid.TableConfigParams) {
    this._tableConfig = Object.assign({}, params);
    if (this._tableConfig.scopes) {
      this.idAttribute = getIdStringByScopeString(this._tableConfig.scopes);
    }
    this.tableService.setTableParams(this._tableConfig);
  }

  public get tableConfig() {
    return this._tableConfig;
  }

  /**
   * Data provider for grid
   */
  @Input() public set service(service: TmGrid.grid.ApiService) {
    this._service = service;
    this.tableService.apiService = this._service;
    if (!this.idAttribute && service.idAttribute) {
      this.idAttribute = service.idAttribute;
    }
  }

  // аналог service инпута для запроса данных. Более гибкий
  @Input() public getMethod?: (options?: TmApi.GetOptions) => Observable<TmApi.GetArrayResponse<any> | any>;

  /**
   * Используется для отображения предзагруженных данных или если таблица не стучится в сеть (т.е. локальная)
   */
  @Input() public set dataToShow(data: Model[] | undefined | null) {
    this._dataToShow = data;
    this._localApiService.setData(this._dataToShow || []);
    this.refreshIfLocal();
  }

  public get dataToShow() {
    return this._dataToShow;
  }

  private _dataToShow?: Model[] | null;

  /**
   * Set store id to keep table state
   */
  @Input() public storeId: string;

  /**
   * Select state storage
   * TODO: allow to use multiple storages (for example: url allows to share current table state,
   * localstorage allows to keep state on routing between modules)
   */
  @Input() public storeKey: TmGrid.grid.StoreKey = 'url';

  @Input() public displayPagination = true;

  @Input() public rowHeight = 30;
  @Input() public enableBrowserTooltips = true;
  @Input() public height?: number | string;

  public gridHeight$: Observable<number | string>;

  /**
   * 1st item is selected by default, items sorted in ascending order (in pagination)
   */
  @Input() public paginationItems = [100, 10, 50, 1000];

  /**
   * if true make request on init;
   * if false, use updateTableParam method to make first request
   */
  @Input() public initialRequest = true;

  /**
   * Если апи серверное, то игнорировать компараторы AG_GRID
   */
  @Input() public serverApi = true;

  /**
   * Сохранять состояние сортировки при перезагрузке таблицы
   */
  @Input() public saveSorting = false;

  /**
   * Total pages
   */
  public pagesTotal$: Observable<number> = this.tableService.pagesTotal$;

  /**
   * Ag-grid tableReady (initialized) stream
   */
  public initialized$: Observable<GridApi>;
  public gridData$ = this.tableService.dataStable$;

  public selected: Observable<Model[]> = this._selectionsService.allSelected$;
  public selectedIds: Observable<(string | number)[]> = this._selectionsService.allSelectedIds$;

  @Input() public idAttribute: string;

  private _gridOptions: GridOptions = this._getGridDefaultOptions();

  private _service?: TmGrid.grid.ApiService;

  private _tableConfig: TmGrid.grid.TableConfigParams;

  private _destroy$: Subject<void> = new Subject();

  private _initialized$ = new ReplaySubject<GridApi>(1);

  private _clientHeight = 0;

  private _api?: GridApi;

  private _multiPageSelectable = false;

  constructor(
    private _t: TranslateService,
    public tableService: GridInternalLogicService<Model>,
    private _selectionsService: GridInternalSelectionsService<Model>,
    private _localApiService: GridLocalApiService,
    public state: TmGridState,
    private elementRef: ElementRef
  ) {
    this.initialized$ = this._initialized$.asObservable();
    this.gridHeight$ = merge(
      this.tableService.minimumLimitOrTotal$,
      fromEvent(window, 'resize').pipe(switchMapTo(this.tableService.minimumLimitOrTotal$), debounceTime(100))
    ).pipe(map((limit) => this._countSizeOnLimitAndParent(limit)));
  }

  public updateFilter(itemToUpdate: keyof Model | 'query' | string, value: string | number | null | string[]) {
    this.tableService.updateFilter(itemToUpdate, value);
  }

  public updateFilterAndRefresh(
    itemToUpdate: keyof Model | 'query' | string,
    value: string | number | null | string[]
  ) {
    this.updateFilter(itemToUpdate, value);
    this.refresh();
  }

  public sizeColumnsToFit() {
    of(true)
      .pipe(delay(0), takeUntil(this._destroy$))
      .subscribe(() => {
        this._api?.sizeColumnsToFit();
      });
  }

  private refreshIfLocal() {
    if (this.tableService.apiService === this._localApiService && !this.getMethod) {
      this.refresh();
    }
  }

  /**
   * change max amount of items per page
   * @param limit
   */
  public updateLimit(limit: number) {
    if (!this._multiPageSelectable) {
      this.resetSelection();
    }
    this.tableService.selectLimit(limit);
    this.tableService.selectPage(0);
    this.tableService.fetch();
  }
  public ngOnInit(): void {
    if (this.getMethod && this.service) {
      throw Error('choose getMethod OR service');
    }
    this.tableService.gridApi$ = this.initialized$;
    this.tableService.getMethod = this.getMethod;
    this.tableService.restoreState(this.storeId, this.storeKey);
    this.tableService.setTableParams(this._tableConfig);
    this._selectionsService.idAttribute = this.idAttribute;
    this._setLocalApiIfNoProvided();

    if (this.displayPagination) {
      this.state.setPageDefault(0);
      this.state.setPageLimitDefault(this.paginationItems[0]);
    }

    this._killComparators(this._gridOptions);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Go to page
   */
  public goToPage(page: number): void {
    if (!this._multiPageSelectable) {
      this.resetSelection();
    }
    this.tableService.selectPage(page);
    this.tableService.fetch();
  }

  public getFilterStateChange(paramName: string) {
    return this.tableService.filter$.pipe(
      map((filters) => (filters ? filters[paramName] : null)),
      distinctUntilChanged()
    );
  }

  /**
   * Reset current select
   */
  public resetSelection(): void {
    this.tableService.resetSelection();
  }

  /**
   * refetch data
   */
  public refresh(): void {
    this.initialized$.pipe(take(1), takeUntil(this._destroy$)).subscribe(() => this.tableService.fetch());
  }

  public updateSelected(rows: any[]): void {
    this._selectionsService.updateSelected(rows);
  }

  // Select node by id
  public selectById(ids: number | string | (number | string)[]): void {
    this.tableService.selectById(ids);
  }

  public getRowById(id: number | string) {
    return this.tableService.getRowById(id);
  }

  private mergeGridOptions(gridOptionsPatch: GridOptions): void {
    this._gridOptions = Object.assign(this._getGridDefaultOptions(), this._gridOptions, gridOptionsPatch);
  }

  /**
   * including elems on different pages
   */
  public getSelectedIds(): (number | string)[] {
    return this._selectionsService.getAllSelectedIds();
  }
  /**
   * including elems on different pages
   */
  public getSelected(): Model[] {
    return this._selectionsService.getAllSelected();
  }
  // @deprecated лучше использовать удаление из таблицы при помощи апи которое мы в саму таблицу пробрасываем
  public deleteAllSelected(options?: TmApi.GetOptions): Observable<any> {
    return this.tableService.deleteAllSelected(options);
  }

  /**
   * delete items filtered by provided function
   * @param fn
   * @param options
   */
  public deleteByFn(fn: (item: Model) => boolean, options?: TmApi.GetOptions): Observable<any> {
    return this.tableService.deleteByFn(fn, options);
  }

  private _killComparators(options: GridOptions) {
    if (this.serverApi && options.columnDefs) {
      options.columnDefs.forEach((col: any) => {
        col.comparator = () => 0;
      });
    }
  }

  private _listenToDataChanges() {
    this.tableService.dataStable$.pipe(takeUntil(this._destroy$)).subscribe(
      (response) => {
        this._setTableStateForCurrentPage(response);
      },
      (e) => {
        throw new Error(e);
      }
    );
  }
  private _onAgSortChange(event: SortChangedEvent) {
    const sortModel = event.columnApi.getColumnState().filter((m) => m.colId && m.sort) as TmGrid.grid.SortModel[];
    this.tableService.sortBy(sortModel);
    this.refresh();
  }

  private _onAgSelectionChange(e: SelectionChangedEvent) {
    this._selectionsService.onAgSelectionChange(e);
  }

  private _setLocalApiIfNoProvided() {
    if (!this._service) {
      if (!this.idAttribute) {
        throw Error('idAttribute is required for table with local data');
      }
      this.service = this._localApiService;
      this.refreshIfLocal();
    }
  }

  /**
   * Grid initialization
   */
  private _onGridReady(event: GridReadyEvent) {
    this._api = event.api;
    const sort = this.state.getSort() || {};
    const sortKeys = Object.keys(sort);

    if (sortKeys.length && this.saveSorting) {
      event.columnApi.getColumnState().forEach((column) => {
        const sortValue = sort[column.colId] || null;
        event.columnApi.applyColumnState({
          state: [{ colId: column.colId, sort: sortValue as 'asc' | 'desc' | null }],
        });
      });
    }

    const sortModel = event.columnApi.getColumnState().filter((m) => m.colId && m.sort) as TmGrid.grid.SortModel[];
    this.tableService.sortBy(sortModel);
    this.sizeColumnsToFit();
    this._subscribeToLanguageChanges();
    this._listenToDataChanges();

    this._initialized$.next(this._api);
    if (this.initialRequest) {
      this.refresh();
    } else if (this.dataToShow) {
      this.tableService.setItemsToData({ data: this.dataToShow });
    }
  }

  public setData(data: TmApi.GetArrayResponse<any>) {
    this.tableService.setItemsToData(data);
  }

  private _countSizeOnLimitAndParent(limit: number) {
    if (this.height) {
      return this.height;
    }
    const possibleHeight = limit * this.rowHeight + PAGINATION_HEIGHT;

    const parentElement = (this.elementRef.nativeElement as HTMLElement).parentElement!;
    const firstTime = !this._clientHeight;
    const navigationButtonsHeight = this.rowHeight * 2;
    this._clientHeight =
      this._clientHeight > 0 ? this._clientHeight : parentElement.clientHeight - navigationButtonsHeight;
    if (firstTime) {
      Array.from(parentElement.children).forEach((item) => {
        if (!item.matches('tm-grid')) {
          this._clientHeight -= item.clientHeight;
        }
      });
    }
    const countedHeight = possibleHeight > this._clientHeight ? this._clientHeight : possibleHeight;
    const MIN_GRID_SIZE = this.rowHeight * 11;
    return countedHeight > MIN_GRID_SIZE ? countedHeight : MIN_GRID_SIZE;
  }

  private _setTableStateForCurrentPage(response: TmGrid.grid.DataWithMeta<Model>) {
    this._selectionsService.gridRowsData = response.data;

    // Set table data
    this._api?.setRowData(this._selectionsService.gridRowsData);

    if (this._selectionsService.getAllSelectedIds().length) {
      this.selectById(this._selectionsService.getAllSelectedIds());
    }
  }

  private _getGridDefaultOptions(): GridOptions {
    return {
      getRowId: (data) => {
        return `${data.data[this.idAttribute]}`;
      },
      getRowClass: (params) => (params.data.IS_DELETED ? 'deleted-item' : '6'),
      context: { componentParent: this },
      defaultColDef: {
        headerComponentParams: {
          templateUrl: './tm-grid-header.component.html',
        },
        tooltipValueGetter: (data) => {
          return typeof data.value === 'string' ? data.value : '';
        },
      },
      enableBrowserTooltips: this.enableBrowserTooltips,
      tooltipShowDelay: 200,
      getDataPath: undefined,
      onGridReady: this._onGridReady.bind(this),
      onGridColumnsChanged: this.sizeColumnsToFit.bind(this),
      onGridSizeChanged: this.sizeColumnsToFit.bind(this),
      onFirstDataRendered: this.sizeColumnsToFit.bind(this),
      onSortChanged: this._onAgSortChange.bind(this),
      pagination: false, // implemented in our template
      suppressLoadingOverlay: true, // implemented in our template
      suppressNoRowsOverlay: true, // implemented in our template
      fullWidthCellRenderer: FullWidthCellComponent,
      rowSelection: 'multiple',
      rowHeight: this.rowHeight,
      onSelectionChanged: this._onAgSelectionChange.bind(this),
      sortingOrder: ['desc', 'asc'],
      suppressMenuHide: true,
      stopEditingWhenCellsLoseFocus: true,
      suppressCellFocus: true,
      suppressContextMenu: true,
      suppressMovableColumns: true,
      treeData: false,
      resetRowDataOnUpdate: true,
    };
  }

  private _subscribeToLanguageChanges() {
    this._t.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._api?.refreshHeader();
      this._api?.refreshCells();
    });
  }
}
