import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { map, Subject } from 'rxjs';
import { Blank } from 'typings/generated/blank';
import { FingerprintCommon, FingerprintCompileCommon } from 'typings/generated/fingerprint';

@Injectable({ providedIn: 'root' })
export class TmBlankApiService extends TmCollectionLoader<FingerprintCommon> {
  public readonly src = '/api/EtForm';

  public idAttribute = 'FINGERPRINT_ID';

  public dataChanged$ = new Subject<void>();

  public compile(data: FingerprintCompileCommon) {
    const url = `${this.src}/compile`;
    return this._http.post<TmApi.GetResponse<Blank['data']>>(url, data).pipe(map((response) => response.data));
  }
}
